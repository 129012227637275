// Utility Class for Type Guards
import {
  AdjustablePassthroughEnum,
  BaseProductConfiguration,
  ElectricityProductConfiguration,
  FixedPassthroughEnum,
  GasProductConfiguration,
  OptOutLanguage,
  Sustainability,
  SustainabilityTypeEnum
} from '../_model/product-configuration.model';
import {CommodityType} from '../_zen-legacy-common/_models/commodity';
import {ContractSource} from '../_enums/zen-contract.enum';
import {ContractGreenType, ProductModelConfiguration} from '../_model/contract-v4.model';
import {capitalizeEachFirstLetter} from '../_zen-legacy-common/_utils/format-utils';

export class ProductConfigurationUtils {
  static isElectricityProductConfiguration(
    config: BaseProductConfiguration
  ): config is ElectricityProductConfiguration {
    return config.commodityType === CommodityType.Electricity;
  }

  static isGasProductConfiguration(
    config: BaseProductConfiguration
  ): config is GasProductConfiguration {
    return config.commodityType === CommodityType.Gas;
  }

  static getFriendlyAdjustablePassthroughName(value: AdjustablePassthroughEnum): string {
    if (value === undefined || value === null) {
      return 'Not set';
    }
    switch (value) {
      case AdjustablePassthroughEnum.PASSTHROUGH:
        return 'Passthrough';
      case AdjustablePassthroughEnum.FIXED_ADJUSTABLE:
        return 'Fixed Adjustable';
      case AdjustablePassthroughEnum.FIXED_NONADJUSTABLE:
        return 'Fixed Non-Adjustable';
      default:
        return 'Other'; // reminder in case we ever change or modify this enum
    }
  }

  static getFriendlyFixedPassthroughName(value: FixedPassthroughEnum): string {
    if (value === undefined || value === null) {
      return 'Not set';
    }
    switch (value) {
      case FixedPassthroughEnum.PASSTHROUGH:
        return 'Passthrough';
      case FixedPassthroughEnum.FIXED:
        return 'Fixed';
      default:
        return 'Other'; // reminder in case we ever change or modify this enum
    }
  }

  static getFriendlyGasBasisText(basis: FixedPassthroughEnum, basisPricingLocation: string): string {
    return capitalizeEachFirstLetter(this.getFriendlyFixedPassthroughName(basis) + ' | ' + basisPricingLocation);
  }

  static getFriendlyFixedPassthroughNameByCommodity(value: FixedPassthroughEnum, commodity: CommodityType): string {
    if (value === undefined || value === null) {
      return 'Not set';
    }
    switch (value) {
      case FixedPassthroughEnum.PASSTHROUGH:
        return `Passthrough ${commodity === CommodityType.Electricity ? '(to Hub)' : '(to Market at Hub)'}`;
      case FixedPassthroughEnum.FIXED:
        return `Fixed ${commodity === CommodityType.Electricity ? '(to Zone)' : '(to Delivery Hub)'}`;
      default:
        return 'Other'; // reminder in case we ever change or modify this enum
    }
  }

  static getSustainabilityText(productModelConfiguration: ProductModelConfiguration): string {
    const {configuration} = productModelConfiguration || {};
    const {sustainability, renewableName} = configuration as GasProductConfiguration;
    if (!sustainability || !sustainability.included) {
      return 'Not Included';
    }

    if (sustainability.type === SustainabilityTypeEnum.RENEWABLE_ENERGY_CREDITS) {
      if (sustainability?.certified) {
        return `${sustainability?.percentage}% | Certified ${renewableName} (Green-E)`;
      } else {
        return `${sustainability?.percentage}% | Non-Certified ${renewableName}`;
      }
    } else {
      return `${sustainability?.percentage}% | ${renewableName}`;
    }
  }

  /**
   * Get the contract source from the sustainability and commodity type
   * @param sustainability
   * @param commodityType
   */
  static getLegacySourceFromSustainability(sustainability: Sustainability, commodityType: CommodityType): ContractSource {
    if (!sustainability || !sustainability.included) {
      return commodityType === CommodityType.Electricity ? ContractSource.ELECTRIC_STANDARD_MIX : ContractSource.GAS_NOT_RENEWABLE;
    }

    if (sustainability.type === SustainabilityTypeEnum.RENEWABLE_ENERGY_CREDITS) {
      return sustainability.certified ? ContractSource.ELECTRIC_RENEWABLE_E_CERTIFIED : ContractSource.ELECTRIC_RENEWABLE;
    }

    return ContractSource.GAS_RENEWABLE_CARBON_OFFSETS;
  }

  static getSustainabilityFromLegacy(commodity: CommodityType, contractSource: ContractSource, greenPercent: number): Sustainability {
    if (contractSource === ContractSource.ELECTRIC_STANDARD_MIX || contractSource === ContractSource.GAS_NOT_RENEWABLE) {
      return null;
    }

    return {
      included: true,
      type: commodity === CommodityType.Electricity ? SustainabilityTypeEnum.RENEWABLE_ENERGY_CREDITS : SustainabilityTypeEnum.CARBON_OFFSETS,
      percentage: greenPercent,
      certified: contractSource === ContractSource.ELECTRIC_RENEWABLE_E_CERTIFIED || contractSource === ContractSource.GAS_RENEWABLE_CARBON_OFFSETS,
      source: commodity === CommodityType.Electricity ? ContractGreenType.hybrid : ContractGreenType.other
    }
  }

  static getFriendlyOptOutLanguage(optOutLanguage: OptOutLanguage): string {
    if (optOutLanguage && optOutLanguage?.included) {
      return optOutLanguage?.aoba ? 'AOBA' : 'Yes'
    } else {
      return 'No';
    }
  }

  static getFriendlyCsaText(csa: boolean): string {
    return csa ? 'Yes' : 'No';
  }

  static getFriendlySustainabilityText(sustainability: Sustainability): string {
    let text = '';
    if (!sustainability && !sustainability.included) {
      return 'No';
    } else {
      if (sustainability.type === SustainabilityTypeEnum.RENEWABLE_ENERGY_CREDITS) {
        if (sustainability.certified) {
          text = 'Certified ';
        }
        text += 'Renewable Energy Credits';
      } else {
        text = 'Carbon Offsets';
      }
      text += ' (' + sustainability.percentage + '%)';
    }
    return text;
  }

  static getFriendlySustainabilityShortText(sustainability: Sustainability): string {
    let text = '';
    if (!sustainability || !sustainability.included) {
      return 'No';
    } else {

      if (sustainability.type === SustainabilityTypeEnum.RENEWABLE_ENERGY_CREDITS) {
        text = 'RECs';
      } else {
        text = 'C/Os';
      }
      text += ' (' + sustainability.percentage + '%)';
    }
    return text;
  }
}
