import {Customer} from './customer';
import {ThemeStyles} from '../../../../zen-theme-manager';
import {ZenLocaleModel} from '../../_model/zen-locale.model';

export interface InitResponse {
  locale: ZenLocaleModel;
  organization: Organization;
  themeStyles: ThemeStyles;
}

export interface Organization {
    id: string;
    companyName: string;
    isBroker: boolean;
    defaultEmailFrom: string;
    domainInternal: string;
    domainExternal: string;
    domainExternalStatus: string;
    themeId: string;
    logo: string;
    logoSecondary: string;
    phone: string;
    city: string;
    state: string;
    street1: string;
    street2: string;
    zip: number;
    plan?: OrganizationPlan;
    customers?: Customer[];
    members?: any[];
    imagePath: string;
    imageSmallPath: string;
    logoPrimaryDark: string; // deprecated
    logoPrimaryLight: string; // deprecated
    logoSecondaryDark: string;
    logoSecondaryLight: string;
    created: string;
    updated: string;
    settings: OrganizationSettings;
    tosSigned: boolean;
    rootOrganization: OrganizationRoot;
    parentOrganization: Organization;
    parentOrganizationId: string;
}

export interface OrganizationRoot {
    id: string;
    name: string;
    domain: string;
    baseOrganization: Organization;
    forceSso: boolean;
    ssoUrl: string;
    createDt: Date;
    updateDt: Date;
}

export interface OrganizationSettings {
    id: string;
    organizationId: string;
    acceptSalesAndMarketing: boolean;
    websiteUrl: string;
    emailFooterText: string;
    emailSignatureName: string;
    emailSignatureEmail: string;
    emailSignaturePhone: string;
    emailAutomatedOperation: boolean;
    allowOrgWideContactAccess: boolean;
    allowAdvisorAsCustomerPrimaryContact: boolean;
    customerWidgetUtilityHidden: boolean;
    customerWidgetRateCheckHidden: boolean;
    customerWidgetSavingsHidden: boolean;
    enableSwitchNow: boolean;
    footerCustomerPagesIncluded: boolean;
    footerCustomerPages: string;
    footerAdvisorPagesIncluded: boolean;
    footerAdvisorPages: string;
    footerReportsIncluded: boolean;
    footerReports: string;
    feeMode: FeeMode;
    passwordConfig: PasswordConfig;
    featureGasEnabled: boolean;
    featureOpportunities: boolean;
    featureEnhancedFeeSelector: boolean;
    skipRcUsageCheck: boolean;
}

export interface PasswordConfig {
  name: string;
  label: string;
  pattern: string;
  example: string;
  description: string;
}

export enum FeeMode {
  SHOW_FEE_AND_DETAILS = 'SHOW_FEE_AND_DETAILS', HIDE_ALL = 'HIDE_ALL', SHOW_FEE_ONLY = 'SHOW_FEE_ONLY'
}


export interface OrganizationFeature {
    id: string;
    name: string;
    description: string;

}

export interface OrganizationPlan {
    id: string;
    name: string;
    description: string;
    features: OrganizationFeature[];
}

export interface CustomerOrganization {
    customerId: number;
    organization: Organization;
}
