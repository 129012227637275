import {BasisPricingLocation} from '../../_model/product-configuration.model';

// all the friendly status strings required for the advisor's contract list page,
// customer's contract list page and customer's detail page
export enum ContractFriendlyStatus {
  Processing = 'Processing',
  Customer_Review = 'Customer Review',
  Bidding = 'Bidding',
  Contract_Review = 'Contract Review',
  Contract_Generation = 'Contract Generation',
  Supplier_Review = 'Supplier Review',
  No_Longer_In_Process = 'No Longer In Process',
  Aborted = 'Aborted',
  Declined = 'Declined',
  Timed_Out = 'Timed Out',
  Expired = 'Expired',
  Draft = 'Draft',
  Rejected = 'Rejected',
  Future = 'Future',
  Current = 'Current',
  Default_Service = 'Default Service',
  Booked_Contract = 'Booked Contract',
  On_Utility = 'On Utility',
  Contract_Requested = 'Contract Requested', // isMatrix
}
export enum MLOAAuthorizationFriendlyStatusDescription {
  eSigned = 'E-signed',
  SentToday = 'Sent today, not signed',
  SentBeforeToday = 'Sent but expired, needs to be resent',
  NotSent = 'Not yet sent'
}
export enum RequestStatusDescription {
  Requested = 'Request in process',
  Request = 'Request',
  AuthRequired = 'Authorization required'
}
export enum ContractFriendlyStatusDescription {
  Current = 'In a flowing contract with a 3rd party supplier.',
  Future = 'In a future contract with a third party supplier.',
  Processing = 'Currently pending in another Rate Check flow.',
  On_Utility = 'Taking service from the local utility.',
  Expired = 'Previously in a contract with a 3rd party supplier that has expired or is expiring in the current month.'
}

export function basisPricingLocationToString(basisPricingLocation: BasisPricingLocation) {
  switch (basisPricingLocation) {
    case BasisPricingLocation.BURNER_TIP:
      return 'Burner Tip';
    case BasisPricingLocation.CITY_GATE:
      return 'City Gate';
    default:
      return basisPricingLocation;
  }
}
