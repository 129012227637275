import {Injectable} from '@angular/core';
import {
  DataFlowChildrenDataTableModified,
  DataFlowChildrenDataV4Model,
  DataFlowContractV4Model,
  DataFlowHierarchyCategoryDetailsTableModifiedData,
  DataFlowHierarchyGroupedV4Model,
  DataFlowLenData,
  DataFlowMeterV4Model,
  MeterUtilityRequirements
} from '../../_model/data-flow-hierarchy-v4.model';
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  formatLenAddress,
  formatServiceAddress
} from '../../_zen-legacy-common/_utils/format-utils';
import moment from 'moment/moment';
import {STATES_WITH_ZONE, STATES_WITHOUT_RATE_CLASS} from '../../_zen-legacy-common/_utils/state-utils';
import {ContractFriendlyStatus} from '../../_zen-legacy-common/_utils/contract-utils';
import {ContractStatus} from '../../_enums/zen-contract.enum';
import {DecimalPipe} from '@angular/common';
import {ZenExpansionTableData} from '../../_components/zen-mat-table-with-expansion/zen-mat-table-with-expansion.component';
import {CommodityFeeFormatPipe} from '../../_pipes/commodity-fee-format.pipe';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenNumberFormat} from '../../_enums/zen-number-format.enum';
import {orderBy} from 'lodash';
import {ZenTableColsModel} from '../../_components/zen-mat-table/zen-mat-table.component';
import {ZenHierarchicalGrouping} from '../../_enums/zen-hierarchical-grouping.enum';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {getContractStatusCls, getContractStatusColor} from '../../_utils/zen-contract.util';
import {TranslateService} from '@ngx-translate/core';
import {ZenLocaleModel} from '../../_model/zen-locale.model';
import {ZenCurrencyTypeEnum} from '../../_enums/zen-currency-type.enum';
import {getRcFriendlyStatusCls} from '../../../_modules/zen-rate-checks/_utils/zen-rate-check.util';

@Injectable({
  providedIn: 'root'
})
export class ZenBulkImportTableHelperService {
  zoneColName = 'zoneName';
  rateClsColName = 'rateScheduleCode';
  requiredBillsColName = 'requiredBillsInnerHtml';
  commodity: CommodityType;

  stateList: string[] = [];
  totalMeterCount = 0;

  constructor(private decimalPipe: DecimalPipe,
              public translateSvc: TranslateService,
              private feeFormat: CommodityFeeFormatPipe) {
  }

  getModifiedTableData(groupedRawData: DataFlowHierarchyGroupedV4Model[], commodity: CommodityType, showBillsCol = true): ZenExpansionTableData<DataFlowChildrenDataTableModified,
    DataFlowHierarchyCategoryDetailsTableModifiedData>[] {
    this.commodity = commodity;
    this.stateList = [];
    this.totalMeterCount = 0;

    // Need to show Bill Requirements column, at least one bill required in any one row.
    const atLeastOneBillReqInTable = showBillsCol ? this.checkAtLeastOneBillReqInTable(groupedRawData) : false;


    return  groupedRawData.map((_gd) => {

      const dataLength = _gd?.data?.length || 0;
      const hasSubCategories = _gd.hasSubCategories;
      const topCategoryDetails = this.getCategoryDetails(_gd, atLeastOneBillReqInTable, _gd.data, hasSubCategories);
      const topLen = {lenId: topCategoryDetails.lenId, lenName: topCategoryDetails.lenName, lenAddress: topCategoryDetails.lenAddress} as DataFlowLenData
      const data = dataLength ? _gd?.data.map(c => this.getChildDataChildren(c, topLen)) : [];
      const subCategories = hasSubCategories ? (_gd.subCategories || []).map((subCat) => {
        const subCatLength = subCat.data?.length || 0;
        const hideCols = subCatLength ? this.setHideColumns(subCat.data[0]?.serviceAddress?.serviceAddressState, subCat.data) : [];
        const subCategoryDetails = subCat ? this.getCategoryDetails(subCat, atLeastOneBillReqInTable, subCat.data, hasSubCategories) : null;
        const len = {lenId: subCategoryDetails.lenId, lenName: subCategoryDetails.lenName, lenAddress: subCategoryDetails.lenAddress} as DataFlowLenData
        const subCatData = subCatLength ? subCat.data.map(subData => this.getChildDataChildren(subData, len)) : [];

        return {
          hasSubCategories: subCat.hasSubCategories || false,
          hideCols,
          data: subCatData,
          categoryDetails: subCategoryDetails
        };
      }) : [];

      const hideCols2 = dataLength ? this.setHideColumns(_gd.data[0]?.serviceAddress?.serviceAddressState, _gd.data) : [];
      const categoryDetails2 = this.getCategoryDetails(_gd, atLeastOneBillReqInTable, _gd?.data, false);

      return {
        hasSubCategories,
        hideCols: hideCols2,
        categoryDetails: categoryDetails2,
        data,
        subCategories
      };
    });
  }

  getCategoryDetails(d: DataFlowHierarchyGroupedV4Model, atLeastOneBillReqInTable: boolean, rawChildDataList: DataFlowChildrenDataV4Model[], hasSubCategories: boolean): DataFlowHierarchyCategoryDetailsTableModifiedData {
    const { categoryDetails, counts } = d;
    const catLen = categoryDetails?.len;
    const utilityRequirements = categoryDetails?.utilityRequirements;
    const contract = categoryDetails?.contract;
    const _utilityNames = [...new Set(utilityRequirements?.utilityNames)];

    const billReqIcon = utilityRequirements?.utilityReqMetTotalMeterCount === utilityRequirements?.utilityReqTotalMeterCount ?
      `<i class="material-symbols-rounded color-icon no-bg success-2 p-0 me-3">check_circle</i>` :
      `<i class="material-symbols-rounded color-icon no-bg warning-2-color p-0 me-3">warning</i>`;

    const _totUsage = counts?.totalUsage;
    const _totUsageNative = counts?.totalUsageNative;

    const contractName = this.getContractText(contract);
    const contractEndDate = contract?.contractEndDate;
    const contractStatus = categoryDetails?.contractStatus || contract?.contractStatus;
    const contractSubText = contract ? this.getContractSubText(contract) : null;
    const contractStatusCls = getContractStatusCls(contractStatus as any);
    const contractStatusColor = getContractStatusColor(contractStatus);


    return {
      rowId: categoryDetails.rowId,
      lenId: catLen?.lenId,
      meterIds: rawChildDataList.map(m => m.meter?.meterId),
      lenName: catLen?.lenName,
      lenAddress: catLen ? formatLenAddress(catLen) : null,
      meterCount: counts?.meterCount,
      serviceAddressCount: counts?.serviceAddressCount,
      totalAnnualUsage: _totUsage,
      totalAnnualUsageDthText: _totUsage == null ? '-' : _totUsage === 0 ? '0' : this.decimalPipe.transform(_totUsage),
      totalAnnualUsageNativeText: this.decimalPipe.transform(_totUsageNative).toString(),
      totalAnnualUsageMwhText: _totUsage == null ? '-' : _totUsage === 0 ? '0'  : this.decimalPipe.transform(_totUsage / 1000, ZenNumberFormat.USAGE_MWH),
      billedRequiredText: utilityRequirements?.utilityReqBillsRequired ? `<span class="d-flex align-items-center">${billReqIcon} ${utilityRequirements?.utilityReqMetTotalMeterCount} of ${utilityRequirements?.utilityReqTotalMeterCount}</span>` : '',
      // Hide above billedRequiredText column if there is no bills required in thw whole array.
      hideCols: atLeastOneBillReqInTable ? [] : ['billedRequiredText'],
      contractStatus, contractName, contractEndDate, contractSubText, contractStatusCls, contractStatusColor,
      contractSystemBooked: contract?.contractSystemBooked,
      firstUtilityName: _utilityNames[0],
      utilityNamesPopperArray: _utilityNames.filter((val, i) => i !== 0) // Except firstUtilityName
    };
  }

  getChildDataChildren(c: DataFlowChildrenDataV4Model, backupLen?: DataFlowLenData): DataFlowChildrenDataTableModified {
    const serviceAddress = c.serviceAddress;
    const meter = c.meter;
    const bills = c.bills;
    const len = c.len ? c.len : backupLen;  // Grouping by LEN doesn't work, so we pass in this object to map the missing data.
    const estAnnualUsage = meter?.estimatedAnnualUsage;
    const estAnnualUsageNative = meter?.estimatedAnnualUsageNative;
    this.totalMeterCount += 1; // Setting

    const contract = c.contract;
    const currentContractText = this.getContractText(contract);
    const currentContractSubText = this.getContractSubText(contract);
    const contractStatusColor = getContractStatusColor(contract?.contractStatus);
    const contractStatusCls = getContractStatusCls(contract?.contractStatus as any);

    const rcFirstHistory = c.rateCheckHistory?.[0];
    const rateCheckHistoryStatus = rcFirstHistory?.rateCheckStatus;
    const rateCheckHistoryStatusCls = rcFirstHistory ? getRcFriendlyStatusCls(rcFirstHistory?.rateCheckStatusCode) : null;

    return {
      rowId: c.rowId,
      tags: c.meter?.tags,
      lenId: len?.lenId,
      lenName: len?.lenName,
      lenAddress: len?.lenAddress ? len.lenAddress : len ? formatLenAddress(len) : null,  // If address is already formatted, use it.  Else generate a new one.
      // lenId: c.lenId, // TODO: Need this from the API to update table data
      serviceAddressName: serviceAddress?.serviceAddressName ? serviceAddress.serviceAddressName : serviceAddress?.serviceAddressStreet1,
      serviceAddressState: serviceAddress?.serviceAddressState,
      serviceAddress: formatServiceAddress(serviceAddress),
      utilityAccountNum1: meter?.utilityAccountNum1,
      utilityAccountNum2: meter?.utilityAccountNum2,
      utilityRequirements: meter?.utilityRequirements,
      meterName: meter?.meterName,
      meterDetSubText: this.getMeterSubText(meter),
      annualUsage: estAnnualUsage,
      annualUsageNative: estAnnualUsageNative,
      annualUsageNativeText: (estAnnualUsageNative == null ? '-' : estAnnualUsageNative === 0 ? '0' : this.decimalPipe.transform(estAnnualUsageNative)),
      annualUsageText: estAnnualUsage == null ? '-' : estAnnualUsage === 0 ? '0' : this.decimalPipe.transform(estAnnualUsage),
      // The annualUsageMwhText object is only used for electric RCs.
      annualUsageMwhText: estAnnualUsage == null ? '-' : estAnnualUsage === 0 ? '0' :
        this.decimalPipe.transform(estAnnualUsage / 1000, ZenNumberFormat.USAGE_MWH),
      rateScheduleId: meter?.rateScheduleId,
      rateScheduleCode: meter?.rateScheduleCode,
      zoneId: meter?.zoneId,
      zoneName: meter?.zoneName,
      proposedStartFormatted: meter?.proposedStartDt ?
        moment(meter?.proposedStartDt).format('MMM yyyy') + (meter?.staggeredStart ? ' (Staggered)' : '') : '-',
      requiredBills: bills?.requiredBills || '0', // This field used in the bulk import flow.
      billCount: bills?.data.length > 0 ? bills?.data.length : '0',
      billData: bills?.data,
      currentContractText,
      currentContractSubText,
      contractStatusColor,
      contractStatus: contract?.contractStatus,
      meterId: meter?.meterId,
      unitId: meter?.unitId,
      loadFactor: capitalizeFirstLetter(meter?.loadFactor?.toLowerCase()),
      meterUtilityId: meter?.utilityId,
      meterUtilityName: meter?.utilityName,
      energyPlanId: contract?.energyPlanId,
      serviceAddressId: serviceAddress?.serviceAddressId,
      serviceAddressZip: serviceAddress?.serviceAddressZip,
      contractEndDate: contract?.contractEndDate,
      contractRate: contract?.contractRate,
      contractTerm: contract?.contractTerm,
      estimatedAnnualUsage: estAnnualUsage,
      contractSystemBooked: contract?.contractSystemBooked,
      renewal: contract?.renewal,
      rateCheckHistoryStatus,
      rateCheckHistoryStatusCls,
      contractStatusCls
    };
  }

  getMeterSubText(meter: DataFlowMeterV4Model) {
    let subText = meter?.utilityName || '';

    if (meter?.meterName) {
      subText += `| ${meter.meterName}`;
    }

    if (meter?.utilityAccountNum2) {
      subText += `| ${meter.utilityAccountNum2}`;
    }

    if (meter?.meterAddress2) {
      subText += ` <br/> ${meter.meterAddress2}`;
    }

    if (meter?.meterType) {
      subText += ` | ${meter.meterType}`;
    }

    return subText;
  }

  setHideColumns(state: string, dataChildren: DataFlowChildrenDataV4Model[]): string[] {
    const hideCols = [];

    // Hide zone column if the state is not in STATES_WITH_ZONE
    if (this.commodity === CommodityType.Gas || !STATES_WITH_ZONE.includes(state)) {
      hideCols.push(this.zoneColName);
    }

    // Hide rate class column for Gas or states in STATES_WITHOUT_RATE_CLASS
    if (this.commodity === CommodityType.Gas || STATES_WITHOUT_RATE_CLASS.includes(state)) {
      hideCols.push(this.rateClsColName);
    }

    // Hide required bills column if no bills are required in the child table
    if (dataChildren?.length && !dataChildren.some(d => d.bills?.requiredBills)) {
      hideCols.push(this.requiredBillsColName);
    }

    // Add the state to the state list if it's not already included
    if (!this.stateList.includes(state)) {
      this.stateList.push(state);
    }

    return hideCols;
  }

  getContractText(c: DataFlowContractV4Model) {
    // @ts-ignore
    if (c?.contractStatus === ContractStatus.on_utility || c?.contractStatus === ContractFriendlyStatus.Default_Service) {
      return ContractStatus.on_utility;
    } else if (c?.contractStatus === ContractStatus.draft ) {
      return `${c?.contractSupplier} - Draft`;
    } else {
      const contractStart = moment(c?.contractEndDate).subtract('months', c?.contractTerm).format('MMM yyyy');
      return c?.contractSupplier && c?.contractEndDate ?
        `${c.contractSupplier} | ${contractStart} - ${moment(c.contractEndDate).format('MMM yyyy')}` : '-';
    }
  }

  getContractSubText(c: DataFlowContractV4Model) {
    // TODO: remove the ContractFriendlyStatus once after removing Default_Service status from the API side
    // Update EP response still got the Default_Service status
    // @ts-ignore
    if (c?.contractStatus === ContractStatus.on_utility || c?.contractStatus === ContractFriendlyStatus.Default_Service) {
      return this.getCommodityRate(c?.contractRate);
    } else {
      const swing = c?.contractGasSwingPct ? `${c?.contractGasSwingPct}% Swing` : null;
      return this.getContractSubTextDetails(c, swing);
    }
  }

  getContractSubTextDetails(c: DataFlowContractV4Model, swing: string | null) {
    const details = [
      `${c?.contractTerm || 0}M`,
      `${this.getCommodityRate(c?.contractRate)}`,
      c?.contractRateTypeFriendly.replace(/-/g, ' • '),
      swing,
      capitalizeEachFirstLetter(c?.contractBillingMethod),
      `${c?.contractRenewablePct || 0}% Renewable`,
    ].filter(Boolean).join(' • ');

    return details.replace(/,/g, ' • ');
  }

  getCommodityRate(contractRate: number) {
    return contractRate ? `${this.feeFormat.transform(contractRate, {commodity: this.commodity, currencyType: ZenCurrencyTypeEnum.CURRENCY})}` : '-';
  }

  /**  To check RC selected meters table is valid or not.
   * @param groupedRawData
   */
  checkUtilityBillReqIsInvalid(groupedRawData: DataFlowHierarchyGroupedV4Model[]) {
    const hasBillReq = (grd: DataFlowHierarchyGroupedV4Model) =>
      (grd.categoryDetails?.utilityRequirements?.utilityReqTotalMeterCount !== grd.categoryDetails?.utilityRequirements?.utilityReqMetTotalMeterCount) ||
      grd.subCategories?.some(sc => sc.categoryDetails?.utilityRequirements?.utilityReqTotalMeterCount !== sc.categoryDetails?.utilityRequirements?.utilityReqMetTotalMeterCount);

    return groupedRawData.some(hasBillReq);
  }

  /** Used to show/ hide Bill Requirements column at the parent level. By knowing child level table need bills or not.
   * @param groupedRawData
   */
  checkAtLeastOneBillReqInTable(groupedRawData: DataFlowHierarchyGroupedV4Model[]) {
    const hasBillReq = (grd: DataFlowHierarchyGroupedV4Model) =>
      grd?.categoryDetails?.utilityRequirements?.utilityReqBillsRequired ||
      (grd?.subCategories || []).some(sc => sc.categoryDetails?.utilityRequirements?.utilityReqBillsRequired);

    return groupedRawData.some(hasBillReq);
  }


  getUtilityBillReqInnerHtml(utilityRequirements: MeterUtilityRequirements) {
    if (!utilityRequirements.utilityReqBillsRequired) {
      return '';
    }

    let { utilityReqBillRequirementsMet, utilityReqBillsExistingCount, utilityReqBillsRequiredQuantity } = utilityRequirements;

    // if requirements are met, then update the bill count to the required quantity
    if (utilityReqBillRequirementsMet) {
      utilityReqBillsExistingCount = utilityReqBillsRequiredQuantity;
    }

    return `
    <div class="d-flex align-items-center m-2">
      <span class="${utilityReqBillRequirementsMet || 'danger-1-color'} me-3">
        ${utilityReqBillsExistingCount} of ${utilityReqBillsRequiredQuantity}
      </span>
      <i class="material-symbols-rounded color-icon font-weight-300 pointer no-bg box-shadow ${utilityReqBillRequirementsMet || 'blue-1-color'} p-2">upload</i>
    </div>`;
  }


  getStateList(): string[] {
    return orderBy(this.stateList);
  }

  getTotalUsageFromTableData(groupedRawData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[]): number {
    return groupedRawData.reduce((prev, gd) => {
      const _cdTot = gd.data.reduce((prevCd, cd) => prevCd + cd?.annualUsage, 0);
      return (_cdTot + prev)
    }, 0);
  }
  getTotalUsage(groupedRawData: DataFlowHierarchyGroupedV4Model[]): number {
    return groupedRawData.reduce((prev, gd) => (gd.counts.totalUsage + prev), 0);
  }
  getTotalUsageNative(groupedRawData: DataFlowHierarchyGroupedV4Model[]): number {
    return groupedRawData.reduce((prev, gd) => (gd.counts.totalUsageNative + prev), 0);
  }
  getTotalMetersCount(groupedRawData: DataFlowHierarchyGroupedV4Model[]): number {
    return groupedRawData.reduce((prev, gd) => (gd.counts.meterCount + prev), 0);
  }

  getDataFlowHierarchyTableColumns(_groupBy: ZenHierarchicalGrouping, isElectric: boolean, baseUnit: ZenUnitsEnum):
    { parentCols: ZenTableColsModel[], childCols: ZenTableColsModel[] } {
    const _translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;

    let parentCols: ZenTableColsModel[] = [
      {field: 'meterCount', header: 'Meters', align: 'center', type: 'col-lg'},
      {
        field: 'firstUtilityName', fieldPopperArrObj: 'utilityNamesPopperArray', popperApplyClass: 'min-xs',
        header: _groupBy === ZenHierarchicalGrouping.CONTRACT_STATUS ? null : _translations?.nomenclature?.utilityShort,
        textMaxWidth: '12rem', type: 'text-array', sortColumnName: 'utilityNames', whiteSpace: true
      },
      {
        field: 'totalAnnualUsage', header: `Annual Usage (${baseUnit})`, align: 'center', type: 'standard',
        formatter: (col, rowData: DataFlowHierarchyCategoryDetailsTableModifiedData) => {
          if (isElectric) {
            return rowData.totalAnnualUsageMwhText;
          }
          if (baseUnit !== ZenUnitsEnum.Dth) {
            return rowData.totalAnnualUsageNativeText;
          }

          return rowData.totalAnnualUsageDthText;
        }
      }
    ];

    return {parentCols, childCols: []};
  }
}
