import {Directive, OnDestroy, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {CountryEnum, ZenLocaleModel} from '../../_model/zen-locale.model';
import {CaAndGbZipMask, CaPhoneMask, DefaultZipMask, GbPhoneMask, PhoneMask} from '../../_enums/zen-masks.enum';


// NOTE: Removed AutoUnsubscribe because its causing ObjectUnsubscribedError
// checkProperties -> true if you want to unsubscribe from subscriptions serviceAddresses automatically
@UntilDestroy({checkProperties: true})
@Directive({selector: 'app-zen-base-with-translate'})
export class ZenBaseWithTranslateComponent implements OnInit, OnDestroy {
  _translations: ZenLocaleModel;
  CountryEnum = CountryEnum;
  i18nMask = {
    phone: PhoneMask,
    phoneUnmask: true,
    zip: DefaultZipMask
  };

  constructor(
    public translateSvc: TranslateService
  ) {
    this._translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;

    switch (this._translations.id) {
      case CountryEnum.CA:
        this.i18nMask.zip = CaAndGbZipMask; // For CA and UK we support alphanumeric zip pattern
        this.i18nMask.phone = CaPhoneMask;
        break;
      case CountryEnum.GB:
        this.i18nMask.zip = CaAndGbZipMask; // For CA and UK we support alphanumeric zip pattern
        this.i18nMask.phone = GbPhoneMask;
        break;
      default: // Default US
        this.i18nMask.zip = DefaultZipMask;
        this.i18nMask.phone = PhoneMask;
    }
  }

  ngOnInit(): void {
  }

  // This method must be present, even if empty.
  ngOnDestroy() {
    // We'll throw an error if it doesn't
  }

  get currencyMask() {
    return {
      mask: `${this._translations?.currency?.symbol || '$'}num`,
      blocks: {
        num: {
          mask: Number,
            thousandsSeparator: ',', lazy: true,
            scale: 2, // decimal places
            padFractionalZeros: true,
            radix: '.',  // fractional delimiter
            overwrite: true
        }
      }
    }
  }

   createCurrencyMask() {
    return {
      mask: `${this._translations?.currency?.symbol || '$'}num`,
      blocks: {
        num: {
          mask: Number,
          thousandsSeparator: ',', lazy: true,
          scale: 5, // decimal places
          padFractionalZeros: false,
          normalizeZeros: false,
          radix: '.',  // fractional delimiter
        }
      }
    };
  }

  i18nFormatPhoneNumber(phoneNumberString: string) {
    return i18nFormatPhoneNumber(phoneNumberString, this._translations?.id);
  }

}

export function i18nFormatPhoneNumber(phoneNumberString: string, country: CountryEnum) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  let gbMatch = cleaned.match(/^(\d{4})(\d{6})$/);
  if (match) {
    switch (country) {
      case CountryEnum.CA:
        return `+1 (${match[1]}) ${match[2]}-${match[3]}`; // CA - +1 (000) 000-0000
        break;
      case CountryEnum.GB:
        return `+44 (${gbMatch[1]}) ${gbMatch[2]}`; // GB - +44 0000 000000
      default:
        return `(${match[1]}) ${match[2]}-${match[3]}`; // US - (000) 000-0000
    }
  }
  return null;
}
