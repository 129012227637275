// Enums
import {CommodityType} from '../_zen-legacy-common/_models/commodity';
import {ContractSource} from '../_enums/zen-contract.enum';

export enum AdjustablePassthroughEnum {
  FIXED_ADJUSTABLE = 'FIXED_ADJUSTABLE',
  FIXED_NONADJUSTABLE = 'FIXED_NONADJUSTABLE',
  PASSTHROUGH = 'PASSTHROUGH',
}

export interface OptOutLanguage {
  included: boolean;
  aoba: boolean;
}

export enum PaymentTerm {
    NET_15 = 'Net 15',
    NET_20 = 'Net 20',
    NET_30 = 'Net 30'
}

export enum BasisPricingLocation {
  BURNER_TIP = 'burner tip',
  CITY_GATE = 'city gate'
}

export enum BillingMethod {
  dual = 'dual', consolidated = 'consolidated', bundled = 'bundled'
}

export enum FixedPassthroughEnum {
  FIXED = 'FIXED',
  PASSTHROUGH = 'PASSTHROUGH'
}

export enum SustainabilityTypeEnum {
  RENEWABLE_ENERGY_CREDITS = 'RENEWABLE_ENERGY_CREDITS', CARBON_OFFSETS = 'CARBON_OFFSETS'
}

// Interfaces
export interface Sustainability {
  included: boolean;
  type: SustainabilityTypeEnum;
  percentage: number;
  certified: boolean;
  state?: string;
  source?: ContractSource | string;
}

// BaseProductConfiguration Class
export abstract class BaseProductConfiguration {
  commodityType: CommodityType;
  energy: FixedPassthroughEnum;
  optOutLanguage: OptOutLanguage;
  paymentTerms: PaymentTerm;
  billingMethod: BillingMethod;
  swing = 100; // Default value
  sustainability?: Sustainability;
  basis: FixedPassthroughEnum; // used for GAS and TX electricity


  // IProductConfiguration fields
  renewable: boolean;
  productDescription: string;
  rootProductName: string;
  renewableName: string;

  protected constructor(
    commodityType: CommodityType,
    energy: FixedPassthroughEnum,
    paymentTerms: PaymentTerm,
    billingMethod: BillingMethod,
    renewable: boolean,
    swing: number,
    sustainability?: Sustainability,
    basis?: FixedPassthroughEnum,
    optOutLanguage?: OptOutLanguage
  ) {
    this.commodityType = commodityType;
    this.energy = energy;
    this.optOutLanguage = optOutLanguage;
    this.paymentTerms = paymentTerms;
    this.billingMethod = billingMethod;
    this.renewable = renewable;
    this.sustainability = sustainability;
    this.basis = basis;
    if (swing == null) {
      this.swing = 100;
    } else {
      this.swing = swing;
    }
  }
}

// ElectricityProductConfiguration Class
export class ElectricityProductConfiguration extends BaseProductConfiguration {
  capacity: AdjustablePassthroughEnum;
  transmission: AdjustablePassthroughEnum;

  constructor(
    energy: FixedPassthroughEnum,
    paymentTerms: PaymentTerm,
    billingMethod: BillingMethod,
    renewable: boolean,
    capacity: AdjustablePassthroughEnum,
    transmission: AdjustablePassthroughEnum,
    sustainability?: Sustainability,
    basis?: FixedPassthroughEnum,
    swing?: number,
    optOutLanguage?: OptOutLanguage
  ) {
    super(
      CommodityType.Electricity,
      energy,
      paymentTerms,
      billingMethod,
      renewable,
      swing,
      sustainability,
      basis,
      optOutLanguage
    );
    this.capacity = capacity;
    this.transmission = transmission;
  }
}

// GasProductConfiguration Class
export class GasProductConfiguration extends BaseProductConfiguration {
  basisPricingLocation: BasisPricingLocation;

  constructor(
    energy: FixedPassthroughEnum,
    paymentTerms: PaymentTerm,
    billingMethod: BillingMethod,
    renewable: boolean,
    basisPricingLocation: BasisPricingLocation,
    swing: number,
    sustainability?: Sustainability,
    basis?: FixedPassthroughEnum,
    optOutLanguage?: OptOutLanguage,
  ) {
    super(
      CommodityType.Gas,
      energy,
      paymentTerms,
      billingMethod,
      renewable,
      swing,
      sustainability,
      basis,
      optOutLanguage
    );
    this.basisPricingLocation = basisPricingLocation;
    this.basis = basis;
  }
}
