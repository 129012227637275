export enum ContractSource {
  ELECTRIC_STANDARD_MIX = 'standard_mix',
  ELECTRIC_RENEWABLE = 'renewable_not_e_certified',
  ELECTRIC_RENEWABLE_E_CERTIFIED = 'renewable_e_certified',
  // Gas source comes from productModelConfiguration.configuration.carbonOffsetPricing. If true, renewable. If false, not renewable (nat gas)
  GAS_NOT_RENEWABLE = 'not_renewable',
  GAS_RENEWABLE_CARBON_OFFSETS = 'renewable_carbon_offsets'
}

export enum ContractSourceFriendlyText {
  ELECTRIC_STANDARD_MIX = 'Standard Mix',
  RENEWABLE_ELECTRIC = 'Includes RECs (Not Green-e Certified)',
  RENEWABLE_E_CERTIFIED = 'Includes RECs (Green-e Certified)',
  GAS_STANDARD_MIX = 'Not Renewable (Nat Gas)',
  GAS_RENEWABLE = 'Renewable (Carbon Offsets)'
}

// TODO: replace this with getter from the backend
export enum ContractConfiguration {
  // Electricity
  CAP_AND_TRANS_FIXED = 'Cap & Trans Fixed',
  CAPACITY_PASSTHROUGH = 'Capacity Passthrough',
  TRANSMISSION_PASSTHROUGH = 'Transmission Passthrough',
  CAP_AND_TRANS_PASSTHROUGH = 'Cap & Trans Passthrough',
  OH_CAPACITY_FIXED = 'Capacity Fixed',
  // Gas, plus Electricity for TX only
  BASIS_FIXED = 'Basis Fixed',
  BASIS_PASSTHROUGH = 'Basis Passthrough'
}

export enum ContractType {
  GAS = 'GAS',
  ELECTRIC_CUSTOM = 'ELECTRIC_CUSTOM',
  ELECTRIC_MATRIX = 'ELECTRIC_MATRIX'
}

export enum ContractStatus {
  draft = 'Draft',
  on_utility = 'On Utility',
  current = 'Current',
  contracted = 'Contracted',
  processing = 'Processing',
  future = 'Future',
  expired = 'Expired',
  undefined = 'Undefined'
}
