<div [class.zen-input-group]="editable && formCtrl.value">
  <mat-form-field appearance="outline" class="zen-autocomplete"
                  [class.locked]="disabled"
                  [class.left-field]="editable && formCtrl.value"
                  [class.form-field-without-label]="!label"
                  (click)="isOptgroup ? filteredOptionsOptGroup.next(listDataOptGroup || []) : filteredOptions.next(listData)">

    <mat-label>{{label}}</mat-label>

    <ng-container *ngIf="isOptgroup else defaultOptionTpl">

      <mat-select [formControl]="formCtrl" (closed)="searchCtrl.reset({emitEvent: false}); resetOptGroupData()"
                  [panelWidth]="panelWidth">

        <div class="select-search-input-wrapper">
          <div class="select-search-input-container">
            <mat-form-field [appearance]="'outline'">
              <mat-icon class="text-color ps-1 pe-0" matPrefix>search</mat-icon>
              <input (keydown)="$event.stopPropagation()" [formControl]="searchCtrl" matInput placeholder="Search...">
            </mat-form-field>
          </div>
        </div>

        <ng-container *ngFor="let group of filteredOptionsOptGroup | async">
          <mat-optgroup [label]="group.name" [disabled]="group.disabled">

            <ng-container *ngFor="let option of group.list">
              <mat-option [value]="option.key"
                          [class.hover-blue-1-color]="option.bolder"
                          (click)="onClick.emit(option)">
                <span [ngClass]="option.bolder ? 'font-weight-bold' : 'font-weight-400'">{{option.value}}</span>
              </mat-option>
            </ng-container>

          </mat-optgroup>
        </ng-container>
      </mat-select>
    </ng-container>

    <ng-template #defaultOptionTpl>
      <input #htmlInputElement matInput [formControl]="formCtrl" [matAutocomplete]="auto" [readonly]="disabled" [id]="inputId"
              (input)="!isExternalSearch ? _filter(formCtrl.value) : null">

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
        <mat-option *ngIf="addable" class="action-button" (click)="handleAddClick.emit()"><i
          class="material-icons-round">add</i>{{addLabel}}
        </mat-option>


          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.key"
                      (click)="onClick.emit(option)"
                      [class.mdc-list-item--selected]="formCtrl?.value === option.key">
            <span [ngClass]="option.bolder ? 'font-weight-bold' : 'font-weight-400'">{{option.value}}</span>
          </mat-option>
        </mat-autocomplete>
    </ng-template>

    <i *ngIf="matPrefixIcon?.icon"
       class="material-icons color-icon p-2 prefix-icon me-3 {{matPrefixIcon?.class}}" matPrefix>{{matPrefixIcon.icon}}</i>
    <i class="material-symbols-rounded" matSuffix>expand_more</i>

    <mat-error *ngIf="formCtrl.touched && formCtrl.hasError('required')">Required Field*</mat-error>
    <mat-error *ngIf="formCtrl.touched && formCtrl.hasError('validoption')">Invalid Option*</mat-error>
  </mat-form-field>

  <div class="right-field btn-action" *ngIf="editable && formCtrl.value" (click)="onEdit.emit(formCtrl.value)">
    <i class="material-icons blue-1-color">edit</i>
  </div>
</div>
