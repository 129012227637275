<mat-menu #childMenu="matMenu" [overlapTrigger]="false" class="panel-min-wd-15">
  <span *ngFor="let childMenu of menuItems">

    <!-- Handle branch node menu items -->
    <span *ngIf="childMenu?.children && childMenu?.children?.length > 0">
      <button mat-menu-item [matMenuTriggerFor]="zenMenuItem.childMenu"
              #childMenuTrigger="matMenuTrigger"
              [class.divider]="childMenu.divider" (mouseover)="childMenuTrigger?.openMenu()">
        <div class="d-flex align-items-center justify-content-between">
          <span>{{childMenu.label}}</span>
          <i class="material-symbols-rounded custom-sub-icon">chevron_right</i>
        </div>
      </button>
      <app-zen-menu-item #zenMenuItem [element]="element"
                         [menuItems]="childMenu.children"></app-zen-menu-item>
    </span>

    <!-- Handle leaf node menu items -->
    <span *ngIf="!childMenu.hide && (!childMenu?.children || childMenu?.children?.length === 0)">
      <!-- Type toggle -->
      <div class="toggle-sec" *ngIf="childMenu.type === 'toggle' && childMenu.field else buttonOption">
        <span class="toggle-text">{{childMenu.label}}</span>
        <mat-slide-toggle [(ngModel)]="element[childMenu?.field]"
                          (ngModelChange)="childMenu.command(element, childMenu)"></mat-slide-toggle>
      </div>

      <!-- Type button -->
      <ng-template #buttonOption>
        <button mat-menu-item (click)="childMenu.command(element)" [disabled]="childMenu.disabled"
                [class.divider]="childMenu.divider">
          <span class="material-symbols-rounded zen-mat-menu-icon" *ngIf="childMenu?.icon">{{childMenu?.icon}}</span>
          {{childMenu.label}}
          <span class="beta-v2 ms-2" *ngIf="childMenu.beta">Beta</span>
        </button>
      </ng-template>

    </span>
  </span>
</mat-menu>
