import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {FeeMask} from '../../_enums/zen-masks.enum';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';
import {dollarsToMils, milsToDollars} from '../../_zen-legacy-common/_utils/currency-utils';
import {RateCheckV4Service} from '../../_services/v4/rate-check-v4.service';
import {ZenFeesEnum} from '../../_enums/zen-rates.enum';
import {FeeDetailsError, RateCheckFeeDetails} from '../../_model/rate-check-v4.model';
import {MatSliderDragEvent} from '@angular/material/slider';
import {BrokerFeesCalculated} from '../../_zen-legacy-common/zen-common-services/tili-services/models/broker-fees';
import {feeErrorCheck} from '../../_utils/zen-fee.util';
import {ZenNumberFormat} from '../../_enums/zen-number-format.enum';
import {TotalFeeBreakdownRcData} from '../../_model/custom/total-fee-breakdown.model';
import {ZenBaseWithTranslateComponent} from '../zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-zen-total-fee-breakdown',
  templateUrl: './zen-total-fee-breakdown.component.html',
  styleUrl: './zen-total-fee-breakdown.component.scss'
})
export class ZenTotalFeeBreakdownComponent extends ZenBaseWithTranslateComponent implements OnInit {
  @Input() rateCheckData: TotalFeeBreakdownRcData;
  @Input() popperElement: NgxPopperjsContentComponent;

  @Output() onFeeDetailsCalculated = new EventEmitter<BrokerFeesCalculated>();
  @Output() onSubmit = new EventEmitter<RateCheckFeeDetails>();

  selectedUsageUnit = new FormControl();
  form: FormGroup;
  FeeMask = FeeMask;
  ZenNumberFormat = ZenNumberFormat;
  CommodityType = CommodityType;
  feeError: FeeDetailsError = {};
  feeDetails: RateCheckFeeDetails = {};
  isElectric: boolean;
  loading: boolean;

  constructor(private orgMgtSvc: OrganizationManagementService,
              private rcV4Svc: RateCheckV4Service,
              private formBuilder: FormBuilder,
              public translateSvc: TranslateService,
              private cdRef: ChangeDetectorRef
              ) {
    super(translateSvc);
  }

  ngOnInit(): void {
    this.isElectric = this.rateCheckData?.commodity === CommodityType.Electricity;
    this.selectedUsageUnit.setValue(this.isElectric ? ZenUnitsEnum.kWh : ZenUnitsEnum.Dth);
    this.loadForm();
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      brokerFee: new UntypedFormControl(null, [Validators.required]),
      platformFee: new UntypedFormControl(null, [Validators.required]),
      totalFee: new UntypedFormControl() // mask input supports only string
    });

    this.orgMgtSvc.calcFeeModePermissions();
    this.recalculateFees(null, this.rateCheckData?.currentTotalFee?.toString());
  }

  /**
   * The fee calculation is handled w.r.t the totalFee or brokerFee. We should pass only one value at a time.
   * @param brokerFeeInput
   * @param totalFeeInput
   */
  recalculateFees(brokerFeeInput?: string, totalFeeInput?: string): void {
    let _totalFee = parseFloat(totalFeeInput);
    let _brokerFee = parseFloat(brokerFeeInput);

    if (this.isElectric) {
      _totalFee = parseFloat(milsToDollars(_totalFee).toFixed(5));
      _brokerFee = parseFloat(milsToDollars(_brokerFee).toFixed(5));
    }

    // We should pass either one of the value (brokerFeeInput or totalFeeInput).
    _totalFee = (totalFeeInput ? _totalFee : null);
    _brokerFee = (brokerFeeInput ? _brokerFee : null);

    this.loading = true;
    this.rcV4Svc.calculateRateCheckFees(this.rateCheckData?.customerId, this.rateCheckData?.rateCheckId, _totalFee, _brokerFee)
      .subscribe({
        next: _feeDetails => {
          this.feeDetails.totalAnnualUsage = _feeDetails.totalAnnualUsage;
          this.feeDetails.arr = _feeDetails.brokerFee * _feeDetails.totalAnnualUsage;

          this.feeDetails.totalFee = _feeDetails.totalFee;
          this.feeDetails.brokerFee = _feeDetails.brokerFee;
          this.feeDetails.platformFee = _feeDetails.platformFee;

          this.feeDetails.minFeeDollarsLimit = _feeDetails.feeSource.platformFee ? _feeDetails.feeSource.platformFee : 0.0;
          this.feeDetails.maxFeeDollarsLimit = this.rateCheckData?.commodity === CommodityType.Gas
            ? ZenFeesEnum.NAT_GAS_MAX_FEE : ZenFeesEnum.ELECTRICITY_MAX_FEE_MILLS;
          this.feeDetails.hasFee = true;

          // The electric fees are handled in mills
          if (this.isElectric) {
            this.feeDetails.totalFee = dollarsToMils(_feeDetails.totalFee);
            this.feeDetails.brokerFee = dollarsToMils(_feeDetails.brokerFee);
            this.feeDetails.platformFee = dollarsToMils(_feeDetails.platformFee);

            this.feeDetails.minFeeDollarsLimit = dollarsToMils(this.feeDetails.minFeeDollarsLimit);
            this.feeDetails.maxFeeDollarsLimit = dollarsToMils(this.feeDetails.maxFeeDollarsLimit);
          }

          this.form.patchValue({
            brokerFee: this.feeDetails.brokerFee?.toString(),
            platformFee: this.feeDetails.platformFee?.toString(),
            totalFee: this.feeDetails.totalFee?.toString()
          }, {emitEvent: false, onlySelf: true});

          this.feeError = feeErrorCheck(this.feeDetails);

          this.onFeeDetailsCalculated.emit(_feeDetails);
          this.loading = false;

          this.cdRef.detectChanges();
        }, error: () => {
          this.loading = false;
        }
      });
  }

  onDragEnd(evt: MatSliderDragEvent) {
    this.form.controls.totalFee.setValue((evt.value || 0)?.toString());
    this.feeDetails.totalFee = Number(this.form.controls.totalFee.value);
    this.recalculateFees(null, this.form.controls.totalFee.value);
  }

  formatLabel(value: number): string {
    return `Total Fee: ${value.toFixed(2)}`;
  }

  submit() {
    if (!this.feeError.hasError) {
      this.onSubmit.emit(this.feeDetails);
      this.closeAndReset();
    }
  }

  closeAndReset() {
    this.popperElement?.hide();
  }

}
