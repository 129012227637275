import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../zen-common-services/_services/authentication.service';
import {OrganizationManagementService} from '../zen-common-services/_services/organization-management.service';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private zenDialogSvc: ZenDialogMsgService,
    private organizationService: OrganizationManagementService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = route.queryParams['token'];

    // DEV-12910
    // If we are a customer user, and we have a token on our URL, lets login with that token
    // This helps scenarios where signatories are shared across customers, and we need to login as a different customer for viewing RCs
    if (token !== undefined && token !== '' && this.authService.isCurrentlyLoggedIn() && this.authService.isCustomer()) {
      return this.handleLoginWithToken(token);
    }

    this.authService.redirectUrl = state.url;
    if (this.authService.isCurrentlyLoggedIn() || this.skipAuth(state)) {
      if (this.authService.isAdvisor() && this.organizationService.organization?.id) {
        // need to get additional organization information
        this.organizationService.getOrganizationPromise(true); // wait for org info before continuing
      }
      return true;
    }

    if (token !== undefined && token !== '') {
      return this.handleLoginWithToken(token);
    }

    this.router.navigate(['/']);
    return false;
  }
  private handleLoginWithToken(token: string): Observable<boolean> {
    return this.authService.loginWithToken(token).pipe(
        map(res => {
          if (res.success) {
            if (this.authService.isAdvisor()) {
              // need to get additional organization information
              this.organizationService.getOrganizationPromise(true); // wait for org info before continuing
            }
            return true;
          }
          console.log('Failed to log in with token');
          this.router.navigate(['/']);
          this.zenDialogSvc.openToast(false, 'Sorry! The link you followed has expired.');
          return false;
        })
    );
  }
  private skipAuth(state: RouterStateSnapshot) {
    return state.url.indexOf('skipAuth=true') !== -1;
  }
}
