<!-- Ref: https://codepen.io/diego-q/pen/qBWjZwr -->
<div class="zen-base-fontsize">
  <div class="zen-navbar-v2">

    <div *ngIf="advisorUserLoggedCustomerName" class="switch-from-customer-to-advisor-dash" (click)="gotoAdvisorDash()">
      <p class="text-md font-weight-500 m-0">You are currently viewing as {{advisorUserLoggedCustomerName}}. Click here to go back to your Advisor Dashboard.</p>
    </div>

    <div class="wrapper">
      <mat-drawer-container class="w-100 {{smallDrawer ? 'small-drawer' : ''}}" [autosize]>
        <mat-drawer #sidenav [mode]="isMobile ? 'over' : 'side'" [(opened)]="isOpened" class="navbar-drawer" [disableClose]="true">
          <!-- Sidebar Holder -->
          <nav id="sidebar">
            <!-- User sec is only for mobile view -->
            <div class="user-section">
              <div class="d-flex justify-content-between align-items-center">
                <i class="material-icons left-toggle" (click)="sidenav.toggle()">arrow_back_ios</i>
                <div class="d-flex align-items-center">
                  <ng-template [ngTemplateOutlet]="userSettingsTemplate"></ng-template>
                </div>
              </div>
            </div>

            <div class="logo-sec" *ngIf="organization">
              <span *ngIf="!smallDrawer" class="material-symbols-rounded left-toggle-sm-drawer-icon" (click)="toggleSmallDrawer()">arrow_back_ios</span>
              <img class="company-logo" height="auto" [src]="companyLogoPath">
            </div>

            <ul class="list-unstyled components">
              <ng-container *ngFor="let menu of menuItems">
                <ng-container *ngIf="menu?.link">
                  <li [routerLink]="menu.link ? navSvc.toRouterLink(menu.link) : null" routerLinkActive="active"
                      (click)="isMobile && !smallDrawer ? closeSideNavAndClearFilters(sidenav) : clearFilters()"
                      *ngIf="!menu.hide">
                    <div class="link-wrap" [ngClass]="menu.styleClass">
                      <i class="material-icons">{{menu.icon}}</i>
                      <a>{{menu.title}}</a>
                    </div>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </nav>
        </mat-drawer>

        <!-- Page Content Holder -->
        <div id="content">
          <!-- Mobile Header Starts -->
          <div class="mobile-header-sec">
            <div class="d-flex align-items-center justify-content-between">
              <i class="material-icons-round menu-icon" (click)="sidenav.toggle()">menu</i>
              <ng-template [ngTemplateOutlet]="userFirstLetterTemp"></ng-template>
            </div>
          </div>
          <!-- Mobile Header Ends -->

          <div class="">
            <div class="pf-header-sec mt-3 px-3">
              <app-zen-breadcrumb *ngIf="isDesktop && !this.zenBreadCrumbService.hide"></app-zen-breadcrumb>
              <div class="d-flex align-items-center">
                <i class="material-icons-round menu-icon" (click)="sidenav.toggle()">menu</i>
              </div>
              <div class="user-sec">
                <ng-template [ngTemplateOutlet]="userSettingsTemplate" [ngTemplateOutletContext]="{id: 'zenTopSettingIcon'}"></ng-template>
              </div>
            </div>

            <div class="mt-4">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </mat-drawer-container>
    </div>

    <!-- Footer starts -->
    <!-- Hide footer for mobile view, when nav opened. -->
    <app-zen-footer *ngIf="!(isMobile && isOpened)" alignment="left-align"></app-zen-footer>
  </div>

  <ng-template #userSettingsTemplate let-id="id">
    <i *ngIf="showSettings" class="material-icons settings" [id]="id" [routerLink]="navSvc.toRouterLink(navSvc.getSettingsPageRoute())">settings</i>
    <ng-template [ngTemplateOutlet]="userFirstLetterTemp"></ng-template>
  </ng-template>


  <ng-template #userFirstLetterTemp>
    <button class="fab-btn pf-fname" [matMenuTriggerFor]="menuUsrProfile">
      <span class="button-label">{{firstLetter}}</span>
    </button>
    <!-- User Profile -->
    <mat-menu #menuUsrProfile="matMenu">
      <div mat-menu-item class="text-center my-3 d-flex justify-content-center">
        <button class="fab-btn circle">
          <span class="button-label">{{firstLetter}}</span>
        </button>
      </div>
      <button mat-menu-item (click)="handleEditUser()">
        <div class="d-flex align-items-center justify-content-between font-weight-600 w-100">
          <span>{{user?.firstName + ' ' + user?.lastName}}</span>
          <i class="material-icons blue-1-color ms-4">edit</i>
        </div>
      </button>
      <button mat-menu-item>
        <span>{{user?.email}}</span>
      </button>
      <!-- Show these if user is able to see fees - allow override of hiding ARR -->
      <hr class="my-2" *ngIf="!auth.isCustomer() && orgSvc.showFee"/>
      <button mat-menu-item *ngIf="!auth.isCustomer() && orgSvc.showFee">
        <span class="me-3">Hide ARR</span>
        <mat-slide-toggle [checked]="orgSvc.hideArr" (change)="orgSvc.setHideArr($event.checked);"></mat-slide-toggle>
      </button>
      <button mat-menu-item (click)="clearCache()">
        <span class="me-3 hover-blue-1-color">Refresh Data</span>
      </button>
      <hr class="my-2"/>
      <button mat-menu-item (click)="auth.logout();">
        <span class="blue-1-color">Logout</span>
      </button>
    </mat-menu>
  </ng-template>
</div>
