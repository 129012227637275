import { RateCheckReportV4Service } from './_services/v3/rate-check-report-v4.service';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../_guards/auth.guard';
import { AuthenticationService } from './_services/authentication.service';
import { LogService } from './_services/log.service';
import { ErrorService } from './_services/error.service';
import { DownloadService } from './_services/download.service';
import { NavigationService } from './_services/navigation.service';
import { CustomerService } from './_services/customer.service';
import { ContractService } from './_services/contract.service';
import { CacheService } from './_services/cache.service';
import { ThemeService } from './_services/common/theme.service';
import { TokenInterceptorService } from './_services/token-interceptor.service';
import { ErrorInterceptorService } from './_services/error-interceptor.service';
import { RateCheckWebReportService } from './_services/v2/rate-check-web-report.service';
import { ProfileService } from './_services/profile.service';
import { ObjectTagService } from './_services/common/object-tag.service';
import { OrganizationManagementService } from './_services/organization-management.service';
import { OrgInterceptorService } from './_services/org-interceptor.service';
import { TosCheckGuard } from '../_guards/tos-check.guard';
import { TemporaryStorageService } from './_services/common/temporary-storage.service';
import {DocusignContractsService} from './_services/docusign/docusign-contracts.service';
import { PaymentsService } from './_services/payments.service';
import {EntityTypeService} from './_services/entity-type.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ZenCommonServicesModule {


  static forRoot(): ModuleWithProviders<ZenCommonServicesModule> {
    return {
      ngModule: ZenCommonServicesModule,
      providers: [
        AuthGuard,
        AuthenticationService,
        LogService,
        ErrorService,
        DownloadService,
        NavigationService,
        CustomerService,
        ContractService,
        CacheService,
        ThemeService,
        // This is an alternative to putting <base href="/"> in the index.html, which breaks Ionic
        // TODO: using '/' here breaks deep linking on page refresh -- need to see what base href actually does
        // {provide: APP_BASE_HREF, useValue: '/'}
        TokenInterceptorService,
        OrgInterceptorService,
        ErrorInterceptorService,
        RateCheckWebReportService,
        ProfileService,
        ObjectTagService,
        OrganizationManagementService,
        TosCheckGuard,
        TemporaryStorageService,
        DocusignContractsService,
        RateCheckReportV4Service,
        PaymentsService,
        EntityTypeService
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule?: ZenCommonServicesModule) {
    if (parentModule) {
      throw new Error(
        'ZenCommonServicesModule is already loaded. Import it in the AppModule only');
    }
  }
}
