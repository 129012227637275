/**
 * Min and max rates for different commodity types. All in dollars.
 */
export enum ZenRatesEnum {
  ELECTRICITY_MIN_RATE = 0.01, // 1 cent
  ELECTRICITY_MAX_RATE = 0.80, // 80 cents
  NAT_GAS_MIN_RATE = 1.00, // 1 dollar = 100 cents
  NAT_GAS_MAX_RATE = 50.00 // 50 dollars = 5000 cents
}

export enum ZenFeesEnum {
  ELECTRICITY_MAX_FEE = 0.12, // 12 cents
  ELECTRICITY_MAX_FEE_MILLS = 0.010, // 10 mills
  NAT_GAS_MAX_FEE = 5.00 // 5 dollars
}

export enum ZenConversionRatesEnum {
  Dth_to_Ccf = 9.804,
  Dth_to_Mcf = 0.98,
  Dth_to_GJ = 1.0550559000000006,
  Dth_to_CubicMeters = 28.33
}
