import {Component, Input, OnInit} from '@angular/core';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';
import {UntypedFormControl} from '@angular/forms';
import {
  FilterClass,
  FilterClassBoolean,
  FilterClassNumber
} from '../../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';

@Component({
  selector: 'app-zen-icon-button-toggle-group',
  templateUrl: './zen-icon-button-toggle-group.component.html',
  styleUrls: ['./zen-icon-button-toggle-group.component.scss']
})
export class ZenIconButtonToggleGroupComponent implements OnInit {
  @Input() formCtrl: UntypedFormControl;
  @Input() title: string;
  @Input() isSymbol: boolean;
  @Input() icon: ZenIconsEnum;
  @Input() options: (FilterClass | FilterClassBoolean | FilterClassNumber)[];
  @Input() popperComponent: NgxPopperjsContentComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
