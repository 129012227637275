import {CommodityType} from '../_zen-legacy-common/_models/commodity';
import {RateCheckStatusEnum} from '../_zen-legacy-common/_models/rate-checks/rate-check-status';
import {UnitDto} from './unit-model';
import {ElectricityProductConfiguration, GasProductConfiguration} from './product-configuration.model';
import {ZenRcReqRenewableOptionsEnum} from '../../_modules/zen-rate-check-request-v2/_enums/rc-req-electricity.enum';

export interface RateCheckRequestV4FlowModel {
  // creation
  canTransact: boolean;
  commodityType: CommodityType;
  csa: boolean;
  customerId: number;
  dateCalculations: {
    maxStartDate: Date
    minStartDate: Date;
    monthGapBetweenDates: number;
    recommendedStartDate: string;
    state: string;
  }; // default true
  defaultUnit: UnitDto;
  disableEmailNotifications: boolean; // default to false
  id: string; // default to false
  incumbentSupplierScenarioIds?: string[];
  isMatrixPricing: boolean;
  meterChanges: RateCheckMeterModificationDto;
  // after creation need special methods to handle these
  productConfiguration: ElectricityProductConfiguration | GasProductConfiguration;
  rcVersion: number; // requires meters to be set first to create the pricing scenarios
  specialRequirements: string;
  // after creation
  startDate: string;
  startingTerms: number[];
  state: string
  statusCode: RateCheckStatusEnum; // Default 2, for the new RCF it will be 4. Used to VIEW_AND_MODIFY_REQUEST
  timeQuotesDue: Date;
  totalFee: number
  validations: RateCheckValidationResponse;
  renewableQuotes: ZenRcReqRenewableOptionsEnum;
}

export interface StaggeredStartProjection {
  meterCount: number;
  startDate: string;
  statuses: string[];
  totalUsage: number;
  unit: string;
  utilityNames: string[];
}

export interface RateCheckValidationResponse {
  csaAllowed: boolean;
  minTerm: number;
  valid: boolean;
  validations: RateCheckValidation[];
}

export interface RateCheckValidation {
  message: string;
  details: string;
  showDetails: boolean;
  validationType: ValidationType;
  commodityScenario: CommodityScenario;
  incumbentSupplierScenario: boolean;
}

export enum ValidationType {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

export enum CommodityScenario {
  ElectricMatrix = 'ElectricMatrix',
  ElectricCustom = 'ElectricCustom',
  GasCustom = 'GasCustom',
}
export interface RateCheckMeterModificationDto {
  ids: (string | number)[];
  objectType: RcRequestObjectType; // Default METER
  modificationType: RateCheckMeterModificationType;
}


export enum RcRequestObjectType {
  LEN = 'LEN', METER = 'METER', UTILITY = 'UTILITY', ENERGY_PLAN = 'ENERGY_PLAN',
  SERVICE_ADDRESS  = 'SERVICE_ADDRESS', BULK_IMPORT = 'BULK_IMPORT', OPPORTUNITY = 'OPPORTUNITY'
}

export enum RateCheckMeterModificationType {
  ADD = 'ADD', REMOVE = 'REMOVE'
}
