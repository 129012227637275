<div class="zen-input-group zen-upload-file">
  <mat-form-field appearance="outline" [class.left-field]="documentName" (click)="!documentName ? fileInput.click() : null">
    <mat-label>{{label}}</mat-label>
    <input matInput readonly [value]="documentName || null" />
    <i class="material-symbols-rounded" matSuffix>upload</i>
  </mat-form-field>
  <div *ngIf="documentName" class="right-field btn-action" (click)="onDelete.emit()">
    <i class="material-icons">close</i>
  </div>
</div>
<div class="zen-upload-file-hint-wrapper" *ngIf="downloadable" (click)="onDownload.emit()">
  <mat-hint class="blue-1-color">Download</mat-hint>
</div>
<input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="file" [accept]="accept">
