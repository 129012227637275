<ng-container *ngIf="feeDetails && rateCheckData?.commodity">

  <ng-container *ngIf="orgMgtSvc.organization?.settings?.featureEnhancedFeeSelector else defaultFeeBreakdownTpl">
    <i class="material-icons mat-form-field-info-icon large position-static"
       [popper]="feeBreakdownCalcPopper" popperTrigger="click">{{ZenIcons.FEE_BREAKDOWN_CALC}}</i>
    <popper-content #feeBreakdownCalcPopper>
      <!-- *ngIf="feeBreakdownCalcPopper.state" - flag is used to init/destroy the app-zen-total-fee-breakdown component
       from the template. Which used to recalculate the fees. -->
      <app-zen-total-fee-breakdown *ngIf="feeBreakdownCalcPopper.state"
                                   [rateCheckData]="rateCheckData"
                                   [popperElement]="feeBreakdownCalcPopper"
                                   (onSubmit)="onSubmit.emit($event)"></app-zen-total-fee-breakdown>
    </popper-content>
  </ng-container>

  <ng-template #defaultFeeBreakdownTpl>
    <i class="material-icons mat-form-field-info-icon ms-3 default-fee-breakdown-icon"
       [popper]="feeBreakdownPopper" popperTrigger="click" popperApplyClass="min-sm">{{ZenIcons.FEE_BREAKDOWN}}</i>

    <popper-content #feeBreakdownPopper>
      <div class="fee-breakdown-wrapper">
        <!-- Fee Breakdown Container for Mwh -->
        <div class="fee-breakdown-container" [class.full-width]="!(platformUnit != rateCheckUnit)">
          <h4 class="fee-header-inline">
            Total Fee Breakdown <span class="unit-label">{{rateCheckUnit}}</span>
          </h4>
          <div class="fee-breakdown">
            <div class="fee-column">
              <div class="fee-row">
                <div class="fee-label">Your Fee</div>
                <div class="fee-value">{{ feeDetails.brokerFee  | currency: _translations?.currency?.abbreviation : 'symbol-narrow' :  ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS  }}</div>
              </div>
              <div class="fee-row">
                <div class="fee-label">Platform Fee</div>
                <div class="fee-value">+ {{ feeDetails.platformFee | currency: _translations?.currency?.abbreviation : 'symbol-narrow' :  ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS }}</div>
              </div>
              <div class="math-separator"></div>
              <div class="fee-row">
                <div class="fee-label">Total Fee</div>
                <div class="fee-value">{{ feeDetails.totalFee | currency: _translations?.currency?.abbreviation : 'symbol-narrow' :  ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS  }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="fee-breakdown-platform" *ngIf="platformUnit != rateCheckUnit">
          <h4 class="fee-header-inline-platform">
            <span class="unit-label">{{platformUnit}}*</span>
          </h4>
          <div class="fee-breakdown">
            <div class="fee-column">
          <div class="fee-row-platform">
            <div class="fee-value-right">{{ feeDetails.brokerFee * platformMultiplier | currency: _translations?.currency?.abbreviation : 'symbol-narrow' :   ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS }}</div>
          </div>
          <div class="fee-row-platform">
            <div class="fee-value-right">+ {{ feeDetails.platformFee * platformMultiplier | currency: _translations?.currency?.abbreviation : 'symbol-narrow' : ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS }}</div>
          </div>
              <div class="math-separator-platform"></div>
          <div class="fee-row-platform">
            <div class="fee-value-right">{{ feeDetails.totalFee * platformMultiplier | currency: _translations?.currency?.abbreviation : 'symbol-narrow' : ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS  }}</div>
          </div>
            </div>
          </div>
          <p class="note">*Platform Default Unit</p>
        </div>
      </div>


    </popper-content>

  </ng-template>
</ng-container>
