import { Injectable } from '@angular/core';
import {ZenTableSelectOptionDataType} from '../../_components/zen-mat-table/zen-mat-table.component';
import moment, {Moment} from 'moment/moment';
import {MatDatepicker} from '@angular/material/datepicker';

@Injectable({
  providedIn: 'root'
})
export class ZenMatTableHelperService {

  constructor() { }

  /** Defining datatype w.r.t the search select field */
  setSearchDataType(objKey: string): ZenTableSelectOptionDataType {
    switch (objKey) {
      case 'contractId':
      case 'energyPlanId':
      case 'usage':
        return ZenTableSelectOptionDataType.NUMBER;
        break;
      case 'planEndDate':
      case 'planStartDate':
      case 'startDate':
      case 'endDate' :
      case 'dueDate':
        return ZenTableSelectOptionDataType.DATE;
        break;
      default:
        return ZenTableSelectOptionDataType.STRING;
        break;
    }
  }

  /** While typing search text that needs to be converted into following datatype to support back end. */
  setInputDataTypeConvert(inputVal: string, dataType: ZenTableSelectOptionDataType) {
    if (inputVal) {
      switch (dataType) {
        case ZenTableSelectOptionDataType.NUMBER:
          return parseInt(inputVal);
          break;
        case ZenTableSelectOptionDataType.DATE:
          return moment(inputVal).format('YYYY-MM-DD');
          break;
        default:
          return inputVal;
          break;
      }
    } else {
      return '';
    }
  }

  // This method set month and year on mat-datepicker -> monthSelected event.
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>, control) {
    const startOfMonth = moment(normalizedMonthAndYear).startOf('month').format('YYYY-MM-DD hh:mm');
    control.setValue(new Date(startOfMonth));
    datepicker.close();
  }

  /** This method is used along with hyperlink: true columns to show checked/unchecked boxes with click action
   * Used in showRateClassPopup() & showZonePopup() as a selectRow column formatter */
  getSelectRowCheckboxFormat(isChecked: boolean) {
      return isChecked ?
        `<i class="material-icons color-icon p-1 checked d-flex align-items-center justify-content-center">check</i>`
        : `<i class="material-icons color-icon p-1 not-selected">check</i>`
  }
}
