import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ItemListV4Model, ItemListWithCount} from '../../_model/item-list-v4.model';

@Injectable({
  providedIn: 'root'
})
export class StateV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, public orgMgtSvc: OrganizationManagementService,) { }

  getStateByCustomerIdAndCommodity(customerId: number, commodityType: CommodityType): Observable<ItemListWithCount[]> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/state-item-list`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('commodityType', commodityType);
    return this.http.get<ItemListWithCount[]>(url, {params: httpParams}).pipe(take(1));
  }

  /**
   * To fetch all states by countryId.
   * @param customerId
   * @param commodityType
   * @param search
   * @param active
   * @param limit
   * @param ignoreCache
   */
  getAllCountryStates(includeFullName = false): Observable<ItemListV4Model[]> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/state-item-list?includeFullName=${includeFullName}`;
    return this.http.get<ItemListV4Model[]>(url).pipe(take(1));
  }

}
