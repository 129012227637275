import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {ZenTableMenuOption} from '../../_components/zen-mat-table/zen-mat-table.component';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {debounceTime} from 'rxjs/operators';
import {DecimalPipe} from '@angular/common';

export enum ZenDialogDataType {
  CONFIRM = 'confirm',
  ADD_DIALOG = 'ADD_DIALOG',
  EDIT_DIALOG = 'EDIT_DIALOG',
  GUIDANCE_SUCCESS = 'guidance_success',
  GUIDANCE_ERROR = 'guidance_error',
  MATRIX_DOWNLOADER = 'matrix',
  UNPROCURABLE_STATUS = 'UNPROCURABLE_STATUS'
}

export enum ButtonTypes {
  MAT_BUTTON = 'mat-button',
  MAT_RAISED_BUTTON = 'mdc-button--raised mat-mdc-raised-button',
  MAT_FLAT_BUTTON = 'mdc-button--flat mat-mdc-flat-button',
}

export enum ButtonActionTypes {
  SUBMIT = 'SUBMIT', CANCEL = 'CANCEL'
}

export interface ZenDialogActionButton {
  label: string;
  btnType?: ButtonTypes;
  color?: 'primary' | 'accent' | 'warn';
  command?: ({}) => void;
  actionType?: ButtonActionTypes;
  styleClass?: string;
  prefixIcon?: string;
  suffixIcon?: string;
  menuItems?: ZenTableMenuOption[];
  showLoader?: boolean;
}

export interface ZenDialogDataModel {
  data?: any;
  type?: ZenDialogDataType;
  header?: {
    title: string; icon?: string; iconClass?: string;
    popperDet?: {title?: string; message: string};
  };
  onClose?: () => void;
  hideCloseX?: boolean;
  bodyText?: string;
  bodyHtml?: string;
  textInput?: {label: string; formCtrl: UntypedFormControl, charLimit?: number};
  numberInput?: {label: string; formCtrl: UntypedFormControl, mask: Object, min?: number; max?: number};
  deleteConfirmation?: {deleteHeader: string; deleteBody: string, deleteClicked?: boolean, deleteCommand?: () => void};
  checkBox?: {label: string; formCtrl: UntypedFormControl};
  actionButtons?: ZenDialogActionButton[];
  radioAction?: {
    formCtrl: FormControl,
    options: {label: string; value: (string | number | boolean)}[];
  }
}

export enum ZenIconColors {
  SUCCESS = 'success_1', DANGER = 'danger_1', WARN = 'warn_1'
}

@Component({
  selector: 'app-zen-dialog',
  templateUrl: './zen-dialog.component.html',
  styleUrls: ['./zen-dialog.component.scss']
})
export class ZenDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  ZenDialogDataType = ZenDialogDataType;
  ButtonActionTypes = ButtonActionTypes;
  icon: { name: string, color: ZenIconColors; };
  showLoadingSpinner = false; // used to control button action loader
  clickedButtonLabel: string; // used to detect button which button was clicked if there are several buttons
  appearance: MatFormFieldAppearance = 'outline';
  spinner = {color: 'primary', diameter: 22.5, strokeWidth: 2.75};
  isNumberOutOfRange = false;
  constructor(
    public dialogRef: MatDialogRef<ZenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
    public translateSvc: TranslateService, public decimalPipe: DecimalPipe
  ) {
    super(translateSvc);
    if (this.data && this.data.type) {
      this.handleIcon();
    }
    if (data.numberInput && !data.numberInput.formCtrl) {
      data.numberInput.formCtrl = new UntypedFormControl('');
    }

    // Run once on initialization if value exists
    const initialValue = data?.numberInput?.formCtrl?.value;
    if (initialValue !== null && initialValue !== undefined && initialValue !== '') {
      this.formatCurrency(initialValue);
    }
  }

  updateIsNumberOutOfRange() {
    const min = this.data.numberInput.min;
    const max = this.data.numberInput.max;
    const numericValue = this.getNumericValue(this.data.numberInput.formCtrl.value);
    this.isNumberOutOfRange = numericValue < min || numericValue > max;
    this.data.numberInput.formCtrl.setErrors(this.isNumberOutOfRange ? { outOfRange: true } : null)
    return this.isNumberOutOfRange;
  }

  // Update number field on blur
  onCurrencyBlur(): void {
    const value = this.data.numberInput.formCtrl.value;
    this.formatCurrency(value);
    this.updateIsNumberOutOfRange()
  }

  // This will pipe the value back through a decimal pipe
  formatCurrency(value: string | number) {
    // Remove everything except digits, dot, minus sign
    const cleaned = typeof value === 'string'
      ? value.replace(/[^0-9.-]+/g, '')
      : value;

    const num = Number(cleaned);

    if (!isNaN(num)) {
      const formatted = this.decimalPipe.transform(num, '1.2-5');
      this.data.numberInput.formCtrl.setValue(formatted, { emitEvent: false });
    }
  }


  ngOnInit(): void {
  }

  handleIcon() {
    switch (this.data.type) {
      case ZenDialogDataType.CONFIRM:
        this.icon = {name: 'warning', color: ZenIconColors.WARN};
        break;
      case ZenDialogDataType.GUIDANCE_ERROR:
        this.icon = {name: 'error_outline', color: ZenIconColors.DANGER};
        break;
      case ZenDialogDataType.GUIDANCE_SUCCESS:
        this.icon = {name: 'check_circle', color: ZenIconColors.SUCCESS};
        break;
    }
  }

  private getNumericValue(value: string): number {
    if (!value) return NaN;
    return parseFloat(value.replace(/[^0-9.-]+/g, ''));
  }

  executeCommand(btn: ZenDialogActionButton): void {
    if ((this.isFormValid() || btn.label === 'Cancel') && btn.command) {
      this.clickedButtonLabel = btn.label; // set label of clicked button to make it easy to detect which button was clicked
      if (btn.showLoader) {
        this.showLoadingSpinner = true; // show button loader
      }
      btn.command(this.data); // execute command
    }
  }

  isFormValid(): boolean {
    let isValid = true;
    if (this.data.numberInput?.formCtrl && !this.data.numberInput.formCtrl.valid) {
      isValid = false;
    }
    if (this.data.textInput?.formCtrl && !this.data.textInput.formCtrl.valid) {
      isValid = false;
    }
    if (this.data.checkBox?.formCtrl && !this.data.checkBox.formCtrl.valid) {
      isValid = false;
    }

    return isValid;
  }

  deleteToggled() {
    this.data.deleteConfirmation.deleteClicked = !this.data.deleteConfirmation.deleteClicked;
  }

}
