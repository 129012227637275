import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ItemListWithCount, ItemListWithCountOptGroup} from '../../_model/item-list-v4.model';
import {ZenBaseComponent} from '../zen-base/zen-base.component';
import {ReplaySubject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';


@Component({
  selector: 'app-zen-autocomplete',
  templateUrl: './zen-autocomplete.component.html',
  styleUrls: ['./zen-autocomplete.component.scss']
})
export class ZenAutocompleteComponent extends ZenBaseComponent implements OnInit, OnChanges {
  @Input() formCtrl: FormControl;
  @Input() panelWidth = 'auto';
  searchCtrl = new FormControl();
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() isExternalSearch = false;
  @Input() isOptgroup = false;

  @Input() editable: boolean;
  // Add Option
  @Input() addable: boolean;
  @Input() addLabel: string;
  @Input() inputId: string;
  @Output() handleAddClick = new EventEmitter<void>();
  @Output() handleExternalSearch = new EventEmitter<string>();


  @Input() listData: ItemListWithCount[] = [];
  filteredOptions = new ReplaySubject<ItemListWithCount[]>();

  @Input() listDataOptGroup: ItemListWithCountOptGroup[] = [];
  listDataOptGroupCopy: ItemListWithCountOptGroup[] = [];
  filteredOptionsOptGroup = new ReplaySubject<ItemListWithCountOptGroup[]>();

  @Input() matPrefixIcon: {icon: ZenIconsEnum; class?: string; };
  @Output() onClick = new EventEmitter<ItemListWithCount>();
  @Output() onEdit = new EventEmitter<ItemListWithCount>();

  prevsSearchVal: string;

  @ViewChild('htmlInputElement') htmlInputElement: ElementRef<HTMLInputElement>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.init();

    if (!this.isExternalSearch) {
      this.searchCtrl.valueChanges
        .subscribe((search) => this._filter(search)); // Search
    }

    // To remove cursor (blur) from the input after select.
    this.onClick.subscribe(() => {
      this.searchCtrl.reset();
      if (this.htmlInputElement?.nativeElement) {
        this.htmlInputElement.nativeElement.blur();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listData || changes.listDataOptGroup) {
      this.init();
    }
  }

  init() {
    if (this.isOptgroup) {
      this.filteredOptionsOptGroup.next(this.listDataOptGroup); // Init
      this.listDataOptGroupCopy = [];
      this.listDataOptGroup.forEach(d => this.listDataOptGroupCopy.push({...d}));
    } else {
      this.filteredOptions.next(this.listData); // Init
    }
  }

  private _filter(value: string): void {
    if (this.isOptgroup) {
      this.resetOptGroupData();
    }

    if (!value || (typeof value === 'string' && value.trim() && value.trim() !== this.prevsSearchVal)) {
      this.prevsSearchVal = value?.trim();
      // Handle external search - ItemList v4 api calls.
      const filterValue = value?.trim().toLowerCase();

      if (this.isOptgroup) {
        // Opt group filter to support filter at child level.
        if (filterValue) {
          const filteredData = [...this.listDataOptGroup].map((d, i) => {
            d.list = d.list?.filter(option => option.value?.toLowerCase().includes(filterValue));
            return d;
          }).filter(d => d.list?.length);
          this.filteredOptionsOptGroup.next([...filteredData]);
        }

      } else {
        if (filterValue) {
          const filteredData = this.listData.filter(option => option.value?.toLowerCase().includes(filterValue));
          this.filteredOptions.next(filteredData);
        } else {
          this.filteredOptions.next(this.listData);
        }
      }

    } else {
      this.filteredOptions.next(this.listData);
    }
  }

  // To support filter re-assigning data values to listDataOptGroup from listDataOptGroupCopy
  resetOptGroupData() {
    this.listDataOptGroup = [];
    this.listDataOptGroupCopy.forEach(d => this.listDataOptGroup.push({...d}));
    this.filteredOptionsOptGroup.next([...this.listDataOptGroupCopy]);
  }

  displayFn(selectedId): string {
    const item: ItemListWithCount = this.listData?.length ? this.listData.find(i => i.key === selectedId) : null;
    return item && item.value ? item.value : '';
  }

}
