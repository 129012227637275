import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, ReplaySubject} from 'rxjs';
import {ContractGreenType, ZenContractV4FormModel, ZenContractV4ListModel} from '../../_model/contract-v4.model';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ButtonTypes, ZenDialogActionButton, ZenDialogDataModel, ZenDialogDataType} from '../../_dialogs/zen-dialog/zen-dialog.component';
import {isValidItemList, isValidOption} from '../../_zen-legacy-common/_utils/validator-utils';
import {CustomerDetails} from '../../../_modules/portfolio/_model/portfolio-customers.model';
import {StateSupplier, Supplier} from '../../_zen-legacy-common/_models/service-address-settings';
import {ContractSource} from '../../_enums/zen-contract.enum';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ItemListV4Service} from '../../_services/v4/item-list-v4.service';
import {TemporaryStorageService} from '../../_zen-legacy-common/zen-common-services/_services/common/temporary-storage.service';
import {CustomerServiceV4} from '../../_services/v4/customer-v4.service';
import {ContractsV4Service} from '../../_services/v4/contracts-v4.service';
import {ZenContractListHelperService} from '../../../_modules/zen-contracts/_services/zen-contract-list-helper.service';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {Moment} from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';
import moment from 'moment/moment';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ZenMessageType} from '../zen-message/zen-message.component';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenBaseComponent} from '../zen-base/zen-base.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {orderBy} from 'lodash';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {ZenRatesEnum} from '../../_enums/zen-rates.enum';
import {ZenUnitsHelperService} from '../../_services/helpers/zen-units-helper.service';
import {TermMask} from '../../_enums/zen-masks.enum';
import {UnitService} from '../../_services/v4/unit-service';
import {UnitDto} from '../../_model/unit-model';
import {ProductConfigurationUtils} from '../../_utils/zen-product_configuration-util';
import {
  AdjustablePassthroughEnum,
  BasisPricingLocation,
  BillingMethod,
  ElectricityProductConfiguration,
  FixedPassthroughEnum,
  GasProductConfiguration
} from '../../_model/product-configuration.model';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-zen-contract-form',
  templateUrl: './zen-contract-form.component.html',
  styleUrls: ['./zen-contract-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class ZenContractFormComponent extends ZenBaseComponent implements OnInit {
  @Input() data: ZenDialogDataModel;
  @Input() errorMsg: string;
  @Input() fieldsToHide: string[] = []; // ['customerName', 'state', 'service']
  @Input() isProcurementSnapshot: boolean;
  disableButton: boolean;

  @Output() afterSubmit: EventEmitter<ZenContractV4ListModel> = new EventEmitter(true);
  @Output() onCancel: EventEmitter<boolean> = new EventEmitter(true);
  @Output() errorResponse: EventEmitter<String> = new EventEmitter(true);
  form: UntypedFormGroup;

  ZenDialogDataType = ZenDialogDataType;


  defaultUnitIds: number[];
  /**
   * The list of suppliers to choose from; depends on what state is selected
   */
  stateOptions: string[] = [];
  public filteredStateOptions: ReplaySubject<string[]> = new ReplaySubject();

  isEditMode: boolean;
  editingContractDet: ZenContractV4ListModel;
  lockedCustomer: ItemListV4Model;
  appearance: MatFormFieldAppearance = 'outline';

  ButtonTypes = ButtonTypes;

  actualDialogData: ZenDialogDataModel;

  // Customer search
  customerCtrl = new UntypedFormControl(null, [Validators.required, isValidItemList()]);
  public filteredCustomers: ReplaySubject<ItemListV4Model[]> = new ReplaySubject();
  searchableCustomers: ItemListV4Model[] = [];

  // Contract Document
  documentName: string;
  uploading: boolean;
  contractDocumentId: number; // After uploading temp file - it will be a document.
  ZenIconsEnum = ZenIconsEnum;

  customerDet: CustomerDetails;

  ZenMessageType = ZenMessageType;

  allStateSuppliers: StateSupplier[] = [];
  // Supplier search
  stateSuppliers: Supplier[] = [];
  supplierCtrl = new UntypedFormControl(null, [Validators.required]);
  public filteredSuppliers: ReplaySubject<Supplier[]> = new ReplaySubject();

  unit = new UntypedFormControl(null, [Validators.required]);
  allUnits: UnitDto[];
  lockedCommodityType: CommodityType;
  lockedState: string;
  lockedCustomerId: number;
  commodityName: 'Gas' | 'Electricity';
  CommodityType = CommodityType;
  NatGasPricingSolutionsEnum = BasisPricingLocation;
  BillingMethod = BillingMethod;

  meterId: number;
  serviceAddressId: number;
  enableSource: boolean;

  rateValidationMsg: string;

  TermMask = TermMask;

  uncheckDraftOnLoad: boolean;

  constructor(private zenDialogSvc: ZenDialogMsgService,
              private formBuilder: UntypedFormBuilder,
              private itemListV4Svc: ItemListV4Service,
              private authSvc: AuthenticationService,
              private tempStorageSvc: TemporaryStorageService,
              private custV4Svc: CustomerServiceV4,
              private contractsV4Svc: ContractsV4Service,
              public zenContractHelper: ZenContractListHelperService,
              private orgMgtSvc: OrganizationManagementService,
              private zenUnitsSvc: ZenUnitsHelperService,
              private unitService: UnitService) {
    super();
    // Taking copy of actual data to support billing address add/edit
    this.actualDialogData = {...this.data};
    this.defaultUnitIds = [];
  }

  get controls() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.unitService.getUnits().subscribe(units => {
      this.allUnits = units

      // Assign meterId, meterId when available
      if (this.data.data.meterId) {
        this.meterId = this.data.data.meterId;
        this.serviceAddressId = this.data.data.serviceAddressId;
      }

      this.uncheckDraftOnLoad = this.data.data.uncheckDraft;

      this.loadForm();
      this.isEditMode = Boolean(this.data.data && this.data.data.energyPlanId);
      this.lockedCommodityType = this.data.data.lockedCommodityType;
      this.lockedState = this.data.data.lockedState;
      this.lockedCustomerId = Number(this.data.data.lockedCustomerId);
      // API data load
      this.loadCustomers();
      this.getAllStateSuppliers();

      if (this.lockedCommodityType) {
        this.controls.commodityType.setValue(this.lockedCommodityType);
        this.commodityName = this.lockedCommodityType === CommodityType.Electricity ? 'Electricity' : 'Gas';
      }

      this.supplierCtrl.valueChanges.subscribe(supplier => {
        if (typeof supplier === 'string') {
          this._supplierFilter(supplier);
        } else {
          this.controls.supplierId.setValue(supplier.supplierId);
        }
      });

      this.unit.valueChanges.subscribe(() => {
        if (this.unit.value && units?.length) {
          this.rateValidationMsg = this.zenUnitsSvc.getRateFieldValidationMsg(this.data.data.lockedCommodityType, this.unit.value, units);
          const {min, max} = this.zenUnitsSvc.getMinAndMaxValidationValue(this.lockedCommodityType, this.unit.value, units);
          this.controls.supplierRate.setValidators([Validators.required, Validators.min(min), Validators.max(max)]);
          this.controls.supplierRate.updateValueAndValidity();
        }
      });

      // This this.unit.setValue() should come after the this.unit.valueChanges
      if (!this.isEditMode) {
        // Setting default unit value for add form
        this.unit.setValue(this.lockedCommodityType === CommodityType.Electricity ? ZenUnitsEnum.kWh : ZenUnitsEnum.Dth);
      }

    });
  }

  private _supplierFilter(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredSuppliers.next(this.stateSuppliers.filter(s => s.supplierName.toLowerCase().includes(filterValue)));
  }

  supplierDisplayFn(item: Supplier): string {
    return item && item.supplierName ? item.supplierName : '';
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      state: new UntypedFormControl(null, [Validators.required, isValidOption(this.stateOptions)]),
      supplierId: new UntypedFormControl(null, [Validators.required]),
      supplierRate: new UntypedFormControl(null, [Validators.required]),
      planEndDate: new UntypedFormControl(null, [Validators.required]),
      termMonths: new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(72)]),
      tempFileId: new UntypedFormControl(),
      commodityType: new UntypedFormControl(null, [Validators.required]),
      rateType: new UntypedFormControl(null, [Validators.required]),
      productModelId: new UntypedFormControl(null),
      nonAdjustable: new UntypedFormControl(null),
      renewablePercent: new UntypedFormControl(0, [Validators.min(0), Validators.max(100)]),
      billingMethod: new UntypedFormControl(),
      draftContract: new UntypedFormControl(false),
      basis: new UntypedFormControl(),
      // Electricity field
      source: new UntypedFormControl(ContractSource.ELECTRIC_STANDARD_MIX),
      // Gas fields
      basisPricingLocation: new UntypedFormControl(),
      swing: new UntypedFormControl(null, [Validators.max(100)]),
    });

    this.controls.commodityType.valueChanges.subscribe(commodityType => {
      this.sourceFieldUpdate();
      // Rate validation
      if (commodityType === CommodityType.Gas) {
        this.controls.supplierRate.setValidators([Validators.required, Validators.min(ZenRatesEnum.NAT_GAS_MIN_RATE), Validators.max(ZenRatesEnum.NAT_GAS_MAX_RATE)]);
      } else {
        this.controls.supplierRate.setValidators([Validators.required, Validators.min(ZenRatesEnum.ELECTRICITY_MIN_RATE), Validators.max(ZenRatesEnum.ELECTRICITY_MAX_RATE)]);
      }
    });

    this.controls.rateType.valueChanges.subscribe(() => {
      this.zenContractHelper.getConfigs(this.controls.commodityType.value, this.controls.rateType.value, this.controls.state.value);
    });

    this.controls.state.valueChanges.subscribe(() => {
      this.zenContractHelper.getConfigs(this.controls.commodityType.value, this.controls.rateType.value, this.controls.state.value);
    });

    this.controls.source.valueChanges.subscribe((source: ContractSource) => {
      this.sourceFieldUpdate();
      // For Electricity and Gas renewable percent is an Optional only if source === STANDARD_MIX or Gas NOT Renewable
      if (source === ContractSource.ELECTRIC_STANDARD_MIX || source === ContractSource.GAS_NOT_RENEWABLE) {
        this.controls.renewablePercent.setValue(0);
        this.controls.renewablePercent.setValidators([]);
      } else {
        this.controls.renewablePercent.setValue(null);
        this.controls.renewablePercent.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
      }
      this.controls.renewablePercent.updateValueAndValidity();
    });

    this.customerCtrl.valueChanges.subscribe(() => {
      this.filterCustomers();
    });

    // listen to changes in State, and filter options accordingly
    this.controls.state.valueChanges.subscribe(() => {
      this.filteredStateOptions.next(this.filterStates());
    });

    this.controls.state.valueChanges.subscribe(_state => {
      this.handleStateSelection(_state);
    });

    this.controls.draftContract.valueChanges.subscribe(draftContract => {
      this.handleDraftContractToggle(draftContract);
    });

    // Initial call to set validators based on the initial value of draftContract
    this.handleDraftContractToggle(this.controls.draftContract.value);
  }

  handleDraftContractToggle(draftContract: boolean) {
    if (draftContract) {
      this.controls.planEndDate.clearValidators();
      this.controls.termMonths.clearValidators();
      this.controls.planEndDate.disable();
      this.controls.termMonths.disable();

      // Update button label
      this.data.actionButtons = this.data.actionButtons.map(btn => {
        if (btn.label === 'Save') {
          btn.label = 'Save as Draft';
        }
        return btn;
      })

    } else {
      this.controls.planEndDate.setValidators([Validators.required]);
      this.controls.termMonths.setValidators([Validators.required, Validators.min(1), Validators.max(72)]);
      this.controls.planEndDate.enable();
      this.controls.termMonths.enable();

      // Update button label
      this.data.actionButtons = this.data.actionButtons.map(btn => {
        if (btn.label === 'Save as Draft') {
          btn.label = 'Save';
        }
        return btn;
      })
    }
    this.controls.planEndDate.updateValueAndValidity();
    this.controls.termMonths.updateValueAndValidity();
  }

  get customerId() {
    return this.customerCtrl.value ? this.customerCtrl.value?.key : null;
  }

  sourceFieldUpdate() {
    this.controls.renewablePercent.markAsUntouched();
    this.enableSource = (this.controls.commodityType.value === CommodityType.Gas || this.controls.source.value !== ContractSource.ELECTRIC_STANDARD_MIX);
  }

  getAllStateSuppliers() {
    this.orgMgtSvc.getStateSuppliers(null, this.lockedCommodityType).subscribe({
      next: suppliers => {
        this.allStateSuppliers = suppliers;
        this.stateOptions = suppliers
          .filter(s => s.suppliers && s.suppliers.length).map(s => s.stateId)
          .sort((a, b) => a.localeCompare(b)); // sort states A-Z
        this.controls.state.setValidators([Validators.required, isValidOption(this.stateOptions)]);
        this.filteredStateOptions.next(this.stateOptions); // To open state dropdown on click.
        if (this.lockedState) {
          this.controls.state.setValue(this.lockedState);
          this.handleStateSelection(this.lockedState);
        }
        // If edit mode, update after this repsonse because we need the state suppliers info
        if (this.isEditMode) {
          this.getContractDetails();
          this.onStateChange()
        }
        this.setDefaultUnitIdsForState();
      }, error: () => {
        this.zenDialogSvc.openToast(false);
      }
    });
  }

  handleStateSelection(_state) {
    this.stateSuppliers = orderBy(this.allStateSuppliers.find(ss => ss.stateId === _state)?.suppliers, 'supplierName');
    this.filteredSuppliers.next(this.stateSuppliers);
  }

  handleCustomerSelection(customerId: number) {
    this.lockedCustomer = this.searchableCustomers.find(c => c.key === customerId);
    this.custV4Svc.getCustomerDetails(customerId).subscribe(customerDet => {
      this.customerDet = customerDet;
    }, e => {
      console.log('Error: Get customer details ', e);
      this.zenDialogSvc.openToast(false);
    });
  }

  onFileInput(files: FileList) {
    this.uploading = true;
    this.disableButton = true;
    this.tempStorageSvc.uploadTempFile(files[0]).then(res => {
      this.documentName = files[0].name;
      this.uploading = false;
      this.disableButton = false;
      this.controls.tempFileId.setValue(res.id);
      this.contractDocumentId = null;
    }, error => {
      console.log('ERROR: Uploading temporary file ', error);
      this.uploading = false;
      this.disableButton = false;
      this.zenDialogSvc.openToast(false);
    });
  }

  downloadDocument() {
    this.tempStorageSvc.downloadDocument(this.customerId, this.contractDocumentId, this.documentName)
      .subscribe(() => {
      }, error => {
        console.log(error);
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false);
      });
  }

  displayCustomerFn(customer: ItemListV4Model): string {
    return customer && customer.value ? customer.value : '';
  }

  // To support edit mode
  getContractDetails() {
    this.contractsV4Svc.getContractById(this.data.data.customerId, this.data.data.energyPlanId).subscribe(contract => {
      let prodConfig = null;
      if (contract.commodityType === CommodityType.Electricity) {
        prodConfig = contract.productModelConfiguration.configuration as ElectricityProductConfiguration;
      } else {
        prodConfig = contract.productModelConfiguration.configuration as GasProductConfiguration;
      }
      this.editingContractDet = contract;
      this.form.patchValue({
        state: contract.state,
        supplierId: contract.supplierId,
        supplierRate: JSON.stringify(contract.supplierRate), // to support imask
        planEndDate: contract.planEndDate,
        termMonths: contract.termMonths ? contract.termMonths?.toString() : null,
        commodityType: contract.commodityType,
        rateType: contract.rateType,
        productModelId: contract.productModelConfiguration?.productModel?.id,
        nonAdjustable: prodConfig?.capacity === AdjustablePassthroughEnum.FIXED_NONADJUSTABLE || prodConfig?.transmission === AdjustablePassthroughEnum.FIXED_NONADJUSTABLE,
        // @ts-ignore
        source: ProductConfigurationUtils.getLegacySourceFromSustainability(prodConfig?.sustainability, contract.commodityType),
        renewablePercent: prodConfig?.sustainability?.percentage,
        billingMethod: prodConfig?.billingMethod,
        // Gas fields
        // @ts-ignore
        basisPricingLocation: prodConfig?.basisPricingLocation,
        // @ts-ignore
        swing: prodConfig?.swing,
        basis: prodConfig?.basis === FixedPassthroughEnum.FIXED ? 'fixed' : 'variable',
        draftContract: contract.planStartDate == null && !this.uncheckDraftOnLoad,
      });

      // Setting unit value for edit form
      this.unit.setValue(this.allUnits.find(u => u.id === contract?.unitId)?.unit);

      this.setDefaultUnitIdsForState();
      this.contractDocumentId = contract.documentId;
      this.documentName = contract.documentId ? this.zenContractHelper.getContractDocFileName(contract) : null; // Filename sanitization
      // Setting supplierId value for edit form
      this.supplierCtrl.setValue({
        supplierId: this.editingContractDet.supplierId,
        supplierName: this.editingContractDet.supplierName
      } as Supplier);
    }, e => {
      console.log('Error: Contact details id ', e);
      this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      this.zenDialogSvc.openToast(false);
    });
  }

  protected filterCustomers() {
    let search = this.customerCtrl.value;
    if (typeof search === 'string') {
      this.loadCustomers(search);
    }
  }

  loadCustomers(search?: string): void {
    if (this.authSvc.isAdvisor() && !this.lockedCustomerId) {
      this.itemListV4Svc.getOrgCustomerItemList(search).subscribe(itemList => {
        this.searchableCustomers = itemList;
        this.filteredCustomers.next(this.searchableCustomers);
        this.handleLockedCustomer();
      }, error => {
        console.log('Error: getCustomerSummaries ', error);
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false);
      });
    } else {
      // Customer item-list API wont work for customers. So, adding locked customer into list to support UI.
      this.addLockedCustomerInSearchList();
    }
  }

  addLockedCustomerInSearchList() {
    this.custV4Svc.getCustomerDetails(this.lockedCustomerId).subscribe(custDet => {
      this.customerDet = custDet;
      this.searchableCustomers.push({value: custDet.companyName, key: custDet.customerId});
      this.filteredCustomers.next(this.searchableCustomers);
      this.handleLockedCustomer();
    }, error => {
      console.log('Error: customer det ', error);
      this.zenDialogSvc.openToast(false);
    });
  }

  // Handling locked customer to support customer details page - Add contract option
  handleLockedCustomer() {
    if (this.lockedCustomerId) {
      setTimeout(() => {
        const customerItem = this.searchableCustomers.find(c => c.key === this.lockedCustomerId);
        if (!this.customerId) {
          this.customerCtrl.setValue(customerItem);
          this.lockedCustomer = customerItem;
        }
      }, 100); // adding this delay to support tags and address api calls
    }
  }

  onStateChange() {
    this.controls.productModelId.setValue(null);

    if (this.controls.state.value === 'TX') {
      this.controls.billingMethod.setValue(this.BillingMethod.consolidated);
    }
    this.setDefaultUnitIdsForState();
  }

  setDefaultUnitIdsForState() {
    // Update default unit Ids when a state is selected
    const stateValue = this.lockedState ?? this.controls.state.value;
    if (stateValue != null && stateValue.length == 2) {
      const stateInfo = this.allStateSuppliers.filter(s => s.stateId == stateValue)[0];
      this.defaultUnitIds = this.commodityName == 'Gas' ? stateInfo.stateUnits?.gasUnitIds : stateInfo.stateUnits?.elecUnitIds;
    }
  }

  disableDialogActions(disable: boolean) {
    this.disableButton = disable;
  }

  filterStates() {
    let filteredStates = [...this.stateOptions];
    let search = this.controls.state.value;
    if (!search) {
      return filteredStates;
    } else {
      search = search.toLowerCase();
      return this.stateOptions.filter(s => s.toLowerCase().includes(search));
    }
  }

  submit(btn: ZenDialogActionButton) {
    this.errorMsg = null;
    const _commodity = this.controls.commodityType.value as CommodityType;

    const _configuration = this.buildContractConfiguration(_commodity);

    const unitId = this.allUnits?.find(u => u.unit === this.unit.value)?.id;
    let _isRenewable = _configuration.sustainability?.included;

    const _formData = {
      ...this.form.value,
      termMonths: parseFloat(this.form.value?.termMonths),
      customerId: this.customerId,
      greenType: _isRenewable ? ContractGreenType.hybrid : null,
      utilityIds: this.editingContractDet?.utilityIds || [],
      productModelConfiguration: {
        renewable: _isRenewable,
        configuration: _configuration,
        productModelId: this.controls.productModelId != null ? this.controls.productModelId.value : null
      },
      supplierRate: parseFloat(this.form.value.supplierRate), // use 'native' rate
      unitId: unitId
    } as ZenContractV4FormModel;

    if (btn.label === 'Cancel') {
      this.errorResponse.emit(null); // If they close add contract section, remove error
      this.onCancel.emit(true);
    } else {
      this.form.markAllAsTouched(); // to trigger mat-error
      this.supplierCtrl.markAllAsTouched();
      this.customerCtrl.markAllAsTouched();
      if (typeof _formData.tempFileId !== 'string') {
        delete _formData.tempFileId;
      }
      if (this.customerId && this.form.valid) {
        if (this.isEditMode) {
          // updating a Contract
          this.handleContractCreateUpdate(this.contractsV4Svc.updateContract(this.customerId, this.data.data.energyPlanId, _formData));
        } else if (!this.isEditMode) {
          // creating a Contract
          this.handleContractCreateUpdate(this.contractsV4Svc.createContracts(this.customerId, _formData, this.meterId, this.serviceAddressId));
        }
      }
    }
  }

  /**
   * Builds the product configuration object based on the form values
   * @param commodity
   * @private
   */
  private buildContractConfiguration(commodity: CommodityType): Partial<ElectricityProductConfiguration> | Partial<GasProductConfiguration> {
    let configuration = {
      energy: this.controls.rateType.value === 'fixed' ? FixedPassthroughEnum.FIXED : FixedPassthroughEnum.PASSTHROUGH,
      billingMethod: this.controls.billingMethod.value,
      commodityType: commodity,
      basis: this.controls.basis.value === 'variable' ? FixedPassthroughEnum.PASSTHROUGH : FixedPassthroughEnum.FIXED,
      swing: this.controls.swing.value,
      sustainability: ProductConfigurationUtils.getSustainabilityFromLegacy(commodity, this.controls.source.value, this.controls.renewablePercent.value),
      ...this.deriveCommoditySpecificProductConfiguration() // add basis for gas and/or capacity and transmission for electricity
    } as Partial<ElectricityProductConfiguration> | Partial<GasProductConfiguration>;

    if (commodity === CommodityType.Gas) {
      configuration = {
        ...configuration,
        basisPricingLocation: this.controls.basisPricingLocation.value
      };
    }
    return configuration;
  }

  handleContractCreateUpdate(sub: Observable<ZenContractV4ListModel>) {
    this.disableDialogActions(true);
    sub.subscribe({
      next: contract => {
        this.zenDialogSvc.openToast(true);
        this.disableDialogActions(false);
        // The below 2 lines used for - In-case after adding a contract, any error occurs this form will react as edit form.
        this.data.data = contract;
        this.isEditMode = true; // After a contract got created
        contract.supplierName = this.supplierCtrl.value?.supplierName;
        this.afterSubmit.emit(contract);
        this.errorResponse.emit(null); // Clear out error messages if successful
      }, error: e => {
        console.log('failed response: ' + e.error.message);
        if (e.error && e.error.status === 400 && e.error.message) {
          if (e.error?.message && (e.error.message.includes('The contract you are trying') || e.error.message.includes('contractDetails'))) { // show only specific error
            this.showSpecificErrorMessage(e.error.message);
          } else {
            this.errorMsg = e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT;
            this.errorResponse.emit(this.errorMsg);
          }
        } else {
          this.errorResponse.emit(e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
        this.disableDialogActions(false);
      }
    });
  }

  // This handles some edge cases around showing error messages for specific scenarios
  showSpecificErrorMessage(errorResponse: string) {
    // Check for specific JSON response error
    if (errorResponse.includes('contractDetails')) {
      let jsonError = JSON.parse(errorResponse);
      let errorMessage = `${jsonError.errorMessage.replace('overlap', 'overlap<br>')}<ul>`; // wrap after overlap, start list
      jsonError.contractDetails.forEach(x => {
        errorMessage += `<li>${x}`; // add bullet points
      })
      errorMessage += `</ul>`;  // close list
      this.errorResponse.emit(errorMessage);
    } else {
      this.errorResponse.emit(errorResponse);
    }
  }


  // This method set month and year on mat-datepicker -> monthSelected event.
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>, yearChange?: boolean) {
    let startOfMonth;
    if (yearChange) {
      // On year selection setting value w.r.t the previous selected month + currently selected year.
      const selectedMonth = this.controls.planEndDate.value ? moment(this.controls.planEndDate.value)?.month() : 1;
      startOfMonth = moment(normalizedMonthAndYear.set('month', selectedMonth)).startOf('month').format('YYYY-MM-DD hh:mm');
    } else {
      startOfMonth = moment(normalizedMonthAndYear).startOf('month').format('YYYY-MM-DD hh:mm');
    }
    this.controls.planEndDate.setValue(new Date(startOfMonth));
    datepicker.close();
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }

  /**
   * Gets the matching default configs from the product model
   */
  getSelectedProductModel() {
    const selectedConfigValue = this.form?.get('productModelId').value;
    return this.zenContractHelper.contractConfigs.find(s => s.value === selectedConfigValue);
  }

  /**
   * Checks if the selected product model is a Fixed product
   */
  showNonAdjustableOption(): boolean {
    // if TX, bounce out quick.  No cap or trans.
    if (this.controls.state.value === 'TX' || this.controls.commodityType.value === CommodityType.Gas) {
      return false;
    }
    let selectedProductModel = this.getSelectedProductModel();
    return selectedProductModel && selectedProductModel.name.includes('Fixed');
  }

  /**
   * Derives the commodity specific product configuration from the selected product model
   */
  deriveCommoditySpecificProductConfiguration(): object {
    let selectedProductModel = this.getSelectedProductModel();
    if (selectedProductModel) {
      // get nonadjustable value from checkbox
      let nonAdjustable = this.form?.get('nonAdjustable').value;

      const transformedObject: Record<string, any> = {}; // object to return

      // loop through the objects.
      // Note: this is a bit of a hack, but we need to transform the object to match the product model
      // when they select a contract configuration, we want to load the appropriate values.  That being said, the UI is simple and doesn't understand complex contracts like basis passthrough
      // so we need to skip basis in those cases.  Or if swing is set to 20%, we can't just set it to 100%.  I've removed swing from the product_model.config column, but we should still be careful.
      // Note on cap and trans:
      // by default the product model default configs in the product model table for electricity always default to FIXED_ADJUSTABLE.
      // so if the value is FIXED_ADJUSTABLE and the nonAdjustable flag is set, we need to change it to FIXED_NONADJUSTABLE
      Object.entries(selectedProductModel.config)
        // need to filter out swing and basis (not in TX) as these cannot just be changed
        .filter(([key, value]) => {
          switch (key) {
            case 'swing':
              return false; // exclude swing from the object
            case 'basis':
              return this.controls.state.value === 'TX' || this.controls.commodityType.value === 'GAS'; // include basis if state is TX or commodity is gas
            default:
              return true;
          }
        })
        .forEach(([key, value]) => {
          if (value === AdjustablePassthroughEnum.FIXED_ADJUSTABLE && nonAdjustable) {
            transformedObject[key] = AdjustablePassthroughEnum.FIXED_NONADJUSTABLE;
          } else {
            transformedObject[key] = value;
          }
        });

      return transformedObject;
    } else {
      return {};
    }
  }
}
